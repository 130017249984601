/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unstable-nested-components */
import { Loader2, MessagesSquare, X, File as FileIcon, Link as LinkIcon } from 'lucide-react';
import React, { useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { MessageSource } from 'models/api/response.types';
import {
  IChatMainColors,
  IChatbotMessageColors,
  WidgetSize,
  chatSizeVariants,
  defaultChatDarkTextColor,
  defaultLightBackgroundColor,
} from 'utils/bot';
import { cn } from 'utils/cn';
import { downloadDataSource } from 'utils/dataSources';
import { encodeText } from 'utils/chatMessage';
import Link from '../MarkdownElements/Link';
import Pre from '../MarkdownElements/Pre';
import Strong from '../MarkdownElements/Strong';
import Code from '../MarkdownElements/Code';

export interface ICitationToRead extends MessageSource {
  key: string;
}

const jsonDisplay = (text: string) => {
  const object = JSON.parse(text);
  return (
    <div>
      <div className="mb-2">
        <h3 className="text-sm font-semibold ">Question:</h3>
        <p className="text-xs ">{object.question}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold">Answer:</h3>
        <p className="text-xs">{object.answer}</p>
      </div>
    </div>
  );
};

const MessageCitation: React.FC<{
  id: string;
  size?: WidgetSize;
  citationToRead: ICitationToRead;
  allowToAccess: boolean;
  chatMainColors?: IChatMainColors;
  chatBotMessageColors?: IChatbotMessageColors;
  goBack: (hide: boolean) => void;
}> = ({ id, size = 'md', citationToRead, allowToAccess, chatMainColors, chatBotMessageColors, goBack }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { type, data_source_uuid, file_name, reference_source_link, title, text } = citationToRead;

  const { isFile, isTable, isUrl, isQA, isVideo, canAccessSources } = useMemo(() => {
    const file = type === 'upload' || type === 'google-drive';
    const url = type === 'url';
    const video = type === 'video';
    const qa = type === 'qa';
    return {
      isFile: file,
      isTable: type === 'table',
      isUrl: url,
      isVideo: video,
      isQA: qa,
      canAccessSources: allowToAccess && (file || url || video || qa || reference_source_link),
    };
  }, []);

  const titleToShow = useMemo(() => {
    // BE ends extra .html to url type sources, need to remove that to show valid url
    if (isUrl) {
      return title.replace(/\.html$/, '');
    }
    return title;
  }, [citationToRead]);

  return (
    <div
      id={id}
      className={cn(
        'rounded-md relative',
        chatSizeVariants.chat.messageCitation.container[size],
        chatSizeVariants.chat.messageCitation.text[size],
      )}
      style={
        chatMainColors
          ? {
              background: chatMainColors.background,
              color: chatMainColors.text,
              borderColor: chatMainColors.text,
            }
          : {
              color: defaultChatDarkTextColor,
              borderColor: defaultChatDarkTextColor,
              background: defaultLightBackgroundColor,
            }
      }
    >
      <div className="flex items-center gap-2 mb-2">
        <div
          className={cn(
            'px-1 rounded-md h-auto cursor-pointer shadow-sm flex items-center',
            chatSizeVariants.chat.messageCitation.key[size],
          )}
          style={
            chatBotMessageColors
              ? {
                  color: chatBotMessageColors.citationReferenceTextColor,
                  backgroundColor: chatBotMessageColors.citationReferenceColor,
                }
              : {
                  color: defaultLightBackgroundColor,
                  backgroundColor: '#367DF1',
                }
          }
          onClick={() => goBack(false)}
        >
          {citationToRead.key}
        </div>
        <p
          className={cn(
            'font-semibold max-w-[58ch] flex items-center',
            chatSizeVariants.chat.messageCitation.text[size],
          )}
          onClick={() => {
            if (canAccessSources) {
              if (reference_source_link) {
                window.open(reference_source_link, '_blank');
              } else if (isFile || isTable) {
                downloadDataSource({ file_name, uuids: [data_source_uuid], canView: !!isFile }, () =>
                  setLoading(false),
                );
              } else if (isQA) {
                const jsonString = JSON.stringify(text || '', null, 2);
                const blob = new Blob([jsonString], {
                  type: 'application/json',
                });
                const dataUrl = URL.createObjectURL(blob);

                // Open the data URL in a new tab
                window.open(dataUrl);

                // Clean up the data URL after opening the tab
                URL.revokeObjectURL(dataUrl);
              } else {
                const url = file_name.replace('.html', '');
                window.open(url, '_blank');
              }
            }
          }}
          style={
            canAccessSources
              ? {
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }
              : {}
          }
        >
          {isFile ? (
            <FileIcon
              strokeWidth={1.75}
              className={cn('mr-2', chatSizeVariants.chat.messageCitation.icon[size])}
            />
          ) : isUrl ? (
            <LinkIcon
              strokeWidth={1.75}
              className={cn('mr-2', chatSizeVariants.chat.messageCitation.icon[size])}
            />
          ) : isVideo ? (
            <img
              src="/img/youtube_logo.webp"
              alt="YouTube"
              className={cn('mr-2 object-contain', chatSizeVariants.chat.messageCitation.icon[size])}
            />
          ) : (
            <MessagesSquare
              strokeWidth={1.75}
              className={cn('mr-2', chatSizeVariants.chat.messageCitation.icon[size])}
            />
          )}
          <span className="line-clamp-1">{titleToShow}</span>
          {loading && (
            <Loader2
              strokeWidth={1.75}
              className={cn('ml-2 animate-spin', chatSizeVariants.chat.messageCitation.icon[size])}
            />
          )}
        </p>
        <button type="button" onClick={() => goBack(true)} className="ml-auto">
          <X strokeWidth={1.75} className={chatSizeVariants.chat.messageCitation.icon[size]} />
        </button>
      </div>
      {isQA ? (
        jsonDisplay(text)
      ) : (
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          className={cn('leading-normal text-inherit border-inherit', `citation ${size}`)}
          components={{
            a: (e) => <Link data={e} />,
            pre: (e) => <Pre data={e} />,
            strong: (e) => <Strong data={e} />,
            code: (e) => <Code data={e} />,
          }}
        >
          {encodeText(text)}
        </ReactMarkdown>
      )}
    </div>
  );
};

export default MessageCitation;
