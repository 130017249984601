import React, { useEffect, useMemo, useState } from 'react';
import { FileCode2, Link as LinkIcon, Search, X } from 'lucide-react';
import '../../components/Chatbot/Integrations/integrations.css';
import CheckIntegrationDialog from 'components/Dialogs/CheckIntegrationDialog';
import { getSelectedChatbot } from 'store/reducers/ui';
import { useSelector } from 'react-redux';
import { Chatbot } from 'models/api/response.types';
import ShareChatbotDialog from 'components/Dialogs/Chatbot/ShareChatbotDialog';
import SiteIntegration from 'components/Chatbot/Integrations/SiteIntegration';
import WixIntegration from 'components/Chatbot/Integrations/WixIntegration';
import SquarespaceIntegration from 'components/Chatbot/Integrations/SquarespaceIntegration';
import DrupalIntegration from 'components/Chatbot/Integrations/DrupalIntegration';
import WordpressIntegration from 'components/Chatbot/Integrations/WordpressIntegration';
import ShopifyIntegration from 'components/Chatbot/Integrations/ShopifyIntegration';
import MessengerIntegration from 'components/Chatbot/Integrations/MessengerIntegration';
import { useLocation } from 'react-router-dom';
import SlackIntegration from 'components/Chatbot/Integrations/SlackIntegration';
import IntegrationUpgradeDialog from 'components/Dialogs/Subscription/IntegrationUpgradeDialog';
import NotionIntegration from 'components/Chatbot/Integrations/NotionIntegration';
import WhatsAppIntegration from 'components/Chatbot/Integrations/WhatsAppIntegration';
import { useAlerts } from 'providers/AlertProvider';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { isDev, isPrimaryProduct } from 'utils/domain';
import { useWhitelabelData } from 'providers/WhiteLabelProvider';
import { alerts } from 'utils/alert';
import { AnimatePresence, motion } from 'framer-motion';
import BubbleIntegration from 'components/Chatbot/Integrations/BubbleIntegration';
import WebflowIntegration from 'components/Chatbot/Integrations/WebflowIntegration';
import InstagramIntegration from 'components/Chatbot/Integrations/InstagramIntegration';
import { Integration, IntegrationTitle, buttonVariants } from 'utils/integrations';
import { Button } from 'components/ui/button';
import HubspotIntegration from 'components/Chatbot/Integrations/HubspotIntegration';
import integrationsService from 'api/integrations';
import { useQueryClient } from '@tanstack/react-query';
import ZendeskIntegration from 'components/Chatbot/Integrations/ZendeskIntegration';

const Integrations: React.FC = () => {
  const { appEmail } = useWhitelabelData();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const { addAlert } = useAlerts();
  const { canUseWebhookIntegrations, canUseFacebookApps, slackEnabled, crmEnabled } = useSubscriptionInfo();
  const slackState = urlSearchParams.get('slack_installation') || '';
  const hubspotState =
    urlSearchParams.get('state') === 'hubspot_create' ? urlSearchParams.get('code') : undefined;
  const [integration, setIntegration] = useState<Integration | undefined>(undefined);
  const [canSeeIntegration, setCanSeeIntegration] = useState<boolean>(false);
  const [checkIntegration, setCheckIntegration] = useState<boolean>(false);
  const [hoveredCard, setHoveredCard] = useState<undefined | 'link' | Integration>(undefined);
  const [shareChatbot, setShareChatbot] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [zapierInviteLink] = useState('https://zapier.com/apps/gpt-trainer/integrations');
  const [makeInviteLink] = useState(
    'https://www.make.com/en/hq/app-invitation/066096884188bc6b173953df718d8808',
  );
  const [showIntegrationUpgradeDialog, setShowIntergationUpgradeDialog] = useState<Integration | undefined>(
    undefined,
  );
  const queryClient = useQueryClient();

  const sendSlackAlert = (severity: 'success' | 'error', message: string) => {
    addAlert({
      severity,
      message,
    });
  };

  const proceedWithCustomLogic = (value: Integration) => {
    if (value === 'zapier') {
      window.open(zapierInviteLink, '_blank');
    }
    if (value === 'make') {
      window.open(makeInviteLink, '_blank');
    }
    if (value === 'link') {
      setCanSeeIntegration(false);
      setShareChatbot(true);
      setIntegration(undefined);
    }
  };

  useEffect(() => {
    if (slackState) {
      if (slackState === 'success') {
        document.getElementById('slack')?.click();
        sendSlackAlert('success', alerts.SLACK_SUCCESS);
      }
      if (slackState === 'failure') {
        sendSlackAlert('error', alerts.SLACK_ERROR);
      }
      window.history.replaceState({}, '', window.location.pathname);
    }
    if (hubspotState) {
      integrationsService
        .createHubspotIntegration({
          chatbot_uuid: chatbot.uuid,
          code: hubspotState,
        })
        .then((hubspot_integration) => {
          addAlert({
            severity: 'success',
            message: alerts.HUBSPOT_SETUP_SUCCESS,
          });
          queryClient.setQueryData(['hubspot-integration', chatbot.uuid], hubspot_integration);
        })
        .catch((err) => {
          if (err?.response?.status === 409) {
            // duplication, already exists in another chatbot
            addAlert({
              severity: 'error',
              message: alerts.HUBSPOT_DUPLICATE_ERROR,
              timeout: 10000,
            });
          } else {
            addAlert({
              severity: 'error',
              message: alerts.HUBSPOT_SETUP_ERROR,
            });
          }
        })
        .finally(() => {
          setIntegration('hubspot');
          setCanSeeIntegration(true);
          window.history.replaceState({}, '', window.location.pathname);
        });
    }
  }, []);

  const hideIntegration = () => {
    setIntegration(undefined);
    setCanSeeIntegration(false);
  };

  const integrations = useMemo(() => {
    return [
      {
        title: IntegrationTitle.link,
        icon: <LinkIcon strokeWidth={1.75} className="w-[40px] h-[40px] text-secondary m-auto" />,
        description: 'Share chatbot link to allow anyone to chat with your chatbot instantly.',
        value: 'link' as Integration,
        customLogic: true,
        buttonText: 'Share Link',
        disabled: false,
      },
      {
        title: IntegrationTitle.site,
        icon: <FileCode2 strokeWidth={1.75} className="w-[50px] h-[50px] text-green-700" />,
        description: 'Integrate chatbot into your website to instantly engage and assist your visitors.',
        value: 'site' as Integration,
        customLogic: false,
        buttonText: 'Integrate Now',
        disabled: false,
      },
      {
        title: IntegrationTitle.slack,
        img: '/img/integrations/slack/slack.png',
        description: 'Integrate chatbot into your website to instantly engage and assist your visitors.',
        value: 'slack' as Integration,
        customLogic: false,
        buttonText: 'Connect Now',
        disabled: !slackEnabled,
      },
      {
        title: IntegrationTitle.zapier,
        img: '/img/integrations/zapier.png',
        description:
          'Automate workflows by connecting our chatbot to Zapier, enabling seamless integration with apps.',
        value: 'zapier' as Integration,
        customLogic: true,
        buttonText: 'Connect Now',
        disabled: !canUseWebhookIntegrations,
      },
      {
        title: IntegrationTitle.zendesk,
        img: '/img/integrations/zendesk/zendesk.png',
        description:
          'Add chatbot to your Zendesk CRM to enhance user engagement with interactive responses to messages.',
        value: 'zendesk' as Integration,
        customLogic: false,
        buttonText: 'Integrate Now',
        disabled: !crmEnabled,
      },
      {
        title: IntegrationTitle.hubspot,
        img: '/img/integrations/hubspot/hubspot.webp',
        description: 'Add chatbot to your Hubspot CRM to implement auto-ticket creation after chat sessions.',
        value: 'hubspot' as Integration,
        customLogic: false,
        buttonText: 'Integrate Now',
        disabled: !crmEnabled,
      },
      {
        title: IntegrationTitle.make,
        img: '/img/integrations/make.png',
        description: 'Simplify workflows by connecting our chatbot with Make for easy app integrations.',
        value: 'make' as Integration,
        customLogic: true,
        buttonText: 'Connect Now',
        disabled: !canUseWebhookIntegrations,
      },
      {
        title: IntegrationTitle.webflow,
        img: '/img/integrations/webflow/webflow.svg',
        description:
          'Integrate our chatbot with Webflow to offer instant support and improve user experience seamlessly.',
        value: 'webflow' as Integration,
        customLogic: false,
        buttonText: 'Integrate Now',
        disabled: false,
      },
      {
        title: IntegrationTitle.notion,
        img: '/img/integrations/notion/notion.png',
        description: 'Integrate chatbot into Notion to enable direct chatting within your workspace.',
        value: 'notion' as Integration,
        buttonText: 'Integrate Now',
        disabled: false,
      },
      {
        title: IntegrationTitle.shopify,
        img: '/img/integrations/shopify/shopify.png',
        description:
          'Enhance your Shopify store by integrating our chatbot to assist and engage customers in real-time.',
        value: 'shopify' as Integration,
        customLogic: false,
        buttonText: 'Integrate Now',
        disabled: false,
      },
      {
        title: IntegrationTitle.whatsapp,
        img: '/img/integrations/whatsapp/whatsapp.png',
        description: 'Integrate your chatbot with WhatsApp Business to engage and assist visitors instantly.',
        value: 'whatsapp' as Integration,
        customLogic: false,
        buttonText: 'Connect Now',
        disabled: !canUseFacebookApps,
      },
      {
        title: IntegrationTitle.instagram,
        img: '/img/integrations/instagram.png',
        description:
          'Link your chatbot to your Instagram Business Account to effortlessly handle DMs and deliver immediate support to your followers.',
        value: 'instagram' as Integration,
        customLogic: false,
        buttonText: 'Connect Now',
        disabled: !canUseFacebookApps,
      },
      {
        title: IntegrationTitle.messenger,
        img: '/img/integrations/messenger.png',
        description:
          ' Connect your chatbot to your Facebook Business Account for automated DM responses and rapid support for your visitors.',
        value: 'messenger' as Integration,
        customLogic: false,
        buttonText: 'Connect Now',
        disabled: !canUseFacebookApps,
      },
      {
        title: IntegrationTitle.wix,
        img: '/img/integrations/wix/wix.png',
        description:
          'Embed our chatbot into your Wix site to interact with visitors and enhance user engagement.',
        value: 'wix' as Integration,
        customLogic: false,
        buttonText: 'Integrate Now',
        disabled: false,
      },
      {
        title: IntegrationTitle.wordpress,
        img: '/img/integrations/wordpress/wordpress.jpg',
        description:
          'Integrate chatbot into your WordPress site to engage visitors and provide immediate support.',
        value: 'wordpress' as Integration,
        customLogic: false,
        buttonText: 'Integrate Now',
        disabled: false,
      },
      {
        title: IntegrationTitle.bubble,
        icon: <img src="/img/integrations/bubble/bubble.png" className="w-[50px] h-[50px]" alt="Bubble" />,
        description: `Integrate your chatbot with Bubble to engage users and provide instant support within your web app.`,
        value: 'bubble' as Integration,
        customLogic: false,
        buttonText: 'Integrate Now',
        disabled: false,
      },
      {
        title: IntegrationTitle.squarespace,
        img: '/img/integrations/squarespace/squarespace.png',
        description:
          'Integrate chatbot into your Squarespace site to provide live chat support and boost visitor interaction.',
        value: 'squarespace' as Integration,
        customLogic: false,
        buttonText: 'Integrate Now',
        disabled: false,
      },
      {
        title: IntegrationTitle.drupal,
        img: '/img/integrations/drupal/drupal.jpg',
        description:
          'Add chatbot to your Drupal site to enhance user engagement with interactive, real-time communication.',
        value: 'drupal' as Integration,
        customLogic: false,
        buttonText: 'Integrate Now',
        disabled: false,
      },
    ].filter((int) => {
      // gpt-trainer only for Slack, Zapier, Make, WhatsApp, Instagram, Messenger, HubSpot, Zendesk
      return !(
        ([
          IntegrationTitle.slack,
          IntegrationTitle.zapier,
          IntegrationTitle.make,
          IntegrationTitle.whatsapp,
          IntegrationTitle.instagram,
          IntegrationTitle.messenger,
          IntegrationTitle.hubspot,
          IntegrationTitle.zendesk,
        ].includes(int.title) &&
          !isPrimaryProduct) ||
        (searchValue && !int.title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
      );
    });
  }, [canUseWebhookIntegrations, searchValue]);

  return (
    <>
      <div className="h-full flex flex-col py-4 px-4 sm:px-6 overflow-y-auto overflow-x-hidden relative">
        <div className="flex pb-4 flex-row items-center gap-3 overflow-x-auto min-h-[52px]">
          <div className="flex items-center gap-2 h-9 w-full md:w-fit min-w-[300px] rounded-md border transition-all  ease-in-out border-input bg-background px-3 pr-2 py-1 text-sm shadow-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring">
            <Search className="h-4 w-4 min-w-[16px]" strokeWidth={1.75} />
            <input
              id="search-integration"
              type="text"
              maxLength={50}
              autoComplete="off"
              placeholder="Search"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              className="w-full h-full placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50"
            />
            {searchValue.length > 0 && (
              <Button
                size="sm"
                variant="ghost"
                onClick={() => {
                  setSearchValue('');
                }}
                type="button"
                className="h-7 w-7"
              >
                <X className="h-4 w-4 min-w-[16px]" strokeWidth={1.75} />
              </Button>
            )}
          </div>
        </div>
        {integrations.length > 0 ? (
          <div className="integration-grid pb-4">
            {integrations.map((int) => (
              <div
                id={int.value}
                key={int.title}
                onMouseEnter={() => setHoveredCard(int.value)}
                onMouseLeave={() => setHoveredCard(undefined)}
                className="relative h-[230px] bg-background rounded-md border flex flex-col items-start w-full cursor-pointer transition-all hover:border-primary p-4"
                onClick={() => {
                  if (int.disabled) {
                    setShowIntergationUpgradeDialog(int.value);
                  } else if (int.customLogic) {
                    proceedWithCustomLogic(int.value);
                  } else if (integration !== int.value) {
                    setCanSeeIntegration(false);
                    setCheckIntegration(true);
                    setIntegration(int.value);
                  }
                }}
              >
                <div className="w-[54px] h-[54px] flex items-center justify-center">
                  {int?.icon ? (
                    int.icon
                  ) : (
                    <img className="m-auto w-[54px] h-[54px] rounded-lg" src={int.img} alt={int.title} />
                  )}
                </div>
                <h1 className="font-medium text-lg mt-2">{int.title}</h1>
                <p className="text-sm text-muted-foreground mt-1">{int.description}</p>
                <AnimatePresence>
                  {hoveredCard === int.value && (
                    <motion.div
                      className="absolute bottom-0 right-0 px-4 py-2 text-sm text-primary"
                      variants={buttonVariants}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      whileHover="hover"
                    >
                      {int.buttonText} →
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
        ) : (
          <div className="w-full flex-1 flex flex-col items-center justify-center">
            <div className="w-full bg-background p-6 rounded-md shadow-sm border max-w-[470px]">
              <h1 className="text-lg font-semibold text-center">No integrations was found for your search</h1>
              <p className="text-muted-foreground text-sm mt-2 text-center">
                You can find itnegrations by name. Refine your search!
              </p>
            </div>
          </div>
        )}
        {appEmail && (
          <p className="text-sm text-center mx-4 mt-auto">
            If you encounter outdated integrations or face setup issues, reach out to us at{' '}
            <a href={`mailto:${appEmail}`} className="text-secondary font-bold hover:underline">
              {appEmail}
            </a>{' '}
            for prompt assistance. We&apos;re here to ensure your seamless experience.
          </p>
        )}
      </div>
      <SiteIntegration show={integration === 'site' && canSeeIntegration} hide={hideIntegration} />
      <WixIntegration show={integration === 'wix' && canSeeIntegration} hide={hideIntegration} />
      <SquarespaceIntegration
        show={integration === 'squarespace' && canSeeIntegration}
        hide={hideIntegration}
      />
      <WebflowIntegration show={integration === 'webflow' && canSeeIntegration} hide={hideIntegration} />
      <DrupalIntegration show={integration === 'drupal' && canSeeIntegration} hide={hideIntegration} />
      <BubbleIntegration show={integration === 'bubble' && canSeeIntegration} hide={hideIntegration} />
      <WordpressIntegration show={integration === 'wordpress' && canSeeIntegration} hide={hideIntegration} />
      <ShopifyIntegration show={integration === 'shopify' && canSeeIntegration} hide={hideIntegration} />
      <SlackIntegration show={integration === 'slack' && canSeeIntegration} hide={hideIntegration} />
      <NotionIntegration show={integration === 'notion' && canSeeIntegration} hide={hideIntegration} />
      <WhatsAppIntegration show={integration === 'whatsapp' && canSeeIntegration} hide={hideIntegration} />
      <InstagramIntegration show={integration === 'instagram' && canSeeIntegration} hide={hideIntegration} />
      <MessengerIntegration show={integration === 'messenger' && canSeeIntegration} hide={hideIntegration} />
      <HubspotIntegration show={integration === 'hubspot' && canSeeIntegration} hide={hideIntegration} />
      <ZendeskIntegration show={integration === 'zendesk' && canSeeIntegration} hide={hideIntegration} />
      {integration && checkIntegration && (
        <CheckIntegrationDialog
          chatbot={chatbot}
          integration={integration}
          close={(success: boolean) => {
            // timeout need to not trigger on click outside listener
            setTimeout(() => {
              if (success) {
                setCanSeeIntegration(true);
              } else {
                setIntegration(undefined);
              }
              setCheckIntegration(false);
            }, 100);
          }}
        />
      )}

      {shareChatbot && (
        <ShareChatbotDialog
          close={() => {
            setShareChatbot(false);
          }}
        />
      )}
      <IntegrationUpgradeDialog
        integration={showIntegrationUpgradeDialog}
        close={() => {
          setShowIntergationUpgradeDialog(undefined);
        }}
      />
    </>
  );
};

export default Integrations;
