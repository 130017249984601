/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
import { Skeleton } from 'components/ui/skeleton';
import useSourceTags from 'hooks/useSourceTags';
import { SourceTag } from 'models/api/response.types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DataRow } from 'utils/sources';
import Tag from '../TagManager/Tag';

const SourceTagList: React.FC<{ source: DataRow }> = ({ source }) => {
  const { sourceTags } = useSourceTags();
  const tagContainerRef = useRef<HTMLDivElement>(null);

  const currentSourceTags = useMemo(() => {
    if (sourceTags) {
      return sourceTags?.filter((tag) => tag.data_source_uuids.includes(source.uuid));
    }
    return undefined;
  }, [sourceTags]);

  const [visibleTags, setVisibleTags] = useState<SourceTag[]>([]);
  const [hiddenCount, setHiddenCount] = useState(0);

  const calculateVisibleTags = () => {
    if (tagContainerRef.current && currentSourceTags) {
      const containerWidth = tagContainerRef.current.offsetWidth;
      let totalWidth = 0;
      const newVisibleTags: SourceTag[] = [];
      let newHiddenCount = 0;

      currentSourceTags.forEach((tag) => {
        const tempSpan = document.createElement('span');
        tempSpan.textContent = tag.name;
        tempSpan.style.visibility = 'hidden';
        document.body.appendChild(tempSpan);

        const tagWidth = tempSpan.offsetWidth + 40;
        document.body.removeChild(tempSpan);

        if (totalWidth + tagWidth <= containerWidth) {
          newVisibleTags.push(tag);
          totalWidth += tagWidth;
        } else {
          newHiddenCount++;
        }
      });

      setVisibleTags(newVisibleTags);
      setHiddenCount(newHiddenCount);
    }
  };

  useEffect(() => {
    const container = tagContainerRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver(() => {
      calculateVisibleTags();
    });

    resizeObserver.observe(container);

    calculateVisibleTags();

    return () => {
      resizeObserver.disconnect();
    };
  }, [currentSourceTags]);

  if (!currentSourceTags) {
    return (
      <div className="flex items-center gap-1">
        <Skeleton className="h-[24px] w-[50px]" />
        <Skeleton className="h-[24px] w-[40px]" />
        <Skeleton className="h-[24px] w-[77px]" />
      </div>
    );
  }

  if (!currentSourceTags.length) {
    return <p className="text-xs text-muted-foreground italic">No tags</p>;
  }

  return (
    <div ref={tagContainerRef} className="flex items-center gap-1">
      {visibleTags.map((tag) => (
        <Tag key={tag.uuid} tag={tag} />
      ))}
      {hiddenCount > 0 && (
        <span className="whitespace-nowrap text-xs text-muted-foreground italic">+{hiddenCount} tags</span>
      )}
    </div>
  );
};

export default SourceTagList;
