import React, { Fragment, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { Textarea } from 'components/ui/textarea';
import { ChatbotFormField } from 'utils/bot';

const AddDropdownOptionsDialog: React.FC<{
  field: ChatbotFormField;
  close: () => void;
  addDropdownOptions: (id: string, value: { dropdown_options: string[] }) => void;
}> = ({ field, close, addDropdownOptions }) => {
  const [optionsName, setOptionsName] = useState<string>(field?.dropdown_options?.join('\n') || '');

  const addOptions = () => {
    const optionResult = optionsName
      .split('\n')
      .map((str) => str.trim())
      .filter((str) => str.length > 0);

    addDropdownOptions(field.id, { dropdown_options: optionResult });
    close();
  };

  return (
    <Transition appear show as={Fragment}>
      <Dialog as="div" className="relative z-[1000] border-md" onClose={close}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                <DialogTitle as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Add dropdown options
                </DialogTitle>
                <div className="mt-4 flex flex-col gap-1">
                  <Textarea
                    autoFocus
                    className="mt-2 min-h-[150px]"
                    id="domains"
                    value={optionsName}
                    placeholder="gpt-trainer.com"
                    onChange={(e) => {
                      setOptionsName(e.target.value);
                    }}
                  />
                  <p className="text-xs text-muted-foreground ml-1">Enter each option on a separate line</p>
                </div>
                <div className="flex items-center gap-4 mt-6 justify-end">
                  <Button variant="outline" onClick={close}>
                    Cancel
                  </Button>
                  <Button onClick={addOptions} disabled={optionsName.length === 0}>
                    Add
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddDropdownOptionsDialog;
