import { useMutation } from '@tanstack/react-query';
import sourceTagService from 'api/tag';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import useSourceTags from 'hooks/useSourceTags';
import { CirclePlus, Loader2, Pencil, X } from 'lucide-react';
import { Chatbot, SourceTag, SourceTagCreateBody, SourceTagUpdateBody } from 'models/api/response.types';
import { useAlerts } from 'providers/AlertProvider';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';
import { alerts } from 'utils/alert';
import { cn } from 'utils/cn';

const defaultColor = '#0071F9';

export const TagForm: React.FC<{ editTag?: SourceTag; stopEditing: () => void }> = ({
  editTag,
  stopEditing,
}) => {
  const { addAlert } = useAlerts();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const { sourceTags, addSourceTag, updateSourceTag, invalidateSourceTagQuery } = useSourceTags();
  const [tagName, setTagName] = useState('');
  const [tagColor, setTagColor] = useState(defaultColor);
  const [loading, setLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTagName(editTag?.name || '');
    setTagColor(editTag?.color || defaultColor);
    if (editTag && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [editTag]);

  const isUniqueName = (name: string) => {
    return sourceTags?.every((tag) => tag.name !== name.trim());
  };

  const canCreateTag = useMemo(() => {
    return tagName.length && isUniqueName(tagName);
  }, [tagName]);

  const canEditTag = useMemo(() => {
    return editTag && ((isUniqueName(tagName) && tagName) || tagColor !== editTag.color);
  }, [editTag, tagName, tagColor]);

  const showInvalidNameError = useMemo(() => {
    // if new tag show error only if tagName.length and name exists
    if (!editTag) {
      return !canCreateTag && tagName;
    }
    return tagName && tagName !== editTag?.name && !isUniqueName(tagName);
  }, [canCreateTag, tagName, editTag]);

  const createSourceTagMutation = useMutation({
    mutationFn: (payload: SourceTagCreateBody) => sourceTagService.createSourceTag(payload),
    onSuccess: (data: SourceTag) => {
      addSourceTag(data);
      setTagName('');
    },
    onError: () => {
      addAlert({
        severity: 'error',
        message: alerts.SOMETHING_WRONG,
      });
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const editSourceTagMutation = useMutation({
    mutationFn: (payload: { id: string; body: SourceTagUpdateBody }) =>
      sourceTagService.updateSourceTag(payload.id, payload.body),
    onMutate: (payload) => {
      updateSourceTag(payload);
    },
    onError: () => {
      addAlert({
        severity: 'error',
        message: alerts.SOMETHING_WRONG,
      });
      invalidateSourceTagQuery();
    },
  });

  const handleAddTag = () => {
    if (tagName.trim()) {
      setLoading(true);
      createSourceTagMutation.mutate({
        name: tagName.trim(),
        color: tagColor,
        chatbot_uuid: chatbot.uuid,
      });
    }
  };

  const handleEditTag = () => {
    editSourceTagMutation.mutate({
      id: editTag?.uuid as string,
      body: {
        name: tagName.trim(),
        color: tagColor,
      },
    });
    stopEditing();
  };

  return (
    <form
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
        if (editTag) {
          handleEditTag();
        } else {
          handleAddTag();
        }
      }}
      className={cn(
        'w-full space-y-4 mt-6 px-1 py-2 border transition-all',
        editTag ? 'border-dashed border-warning rounded-lg' : 'border-transparent',
      )}
    >
      <div className="flex items-start space-x-2">
        <div className="flex-1">
          <Input
            ref={inputRef}
            type="text"
            placeholder="New tag"
            value={tagName}
            onChange={(e) => setTagName(e.target.value)}
            className="flex-grow"
          />
          {showInvalidNameError && (
            <p className="ml-1 text-xs text-destructive mt-1">Tag with this name already exists</p>
          )}
        </div>
        <Popover>
          <PopoverTrigger asChild>
            <div
              className="p-1 rounded-md border flex items-center gap-2 cursor-pointer hover:scale-[1.02] transition-all"
              style={{
                borderColor: tagColor,
              }}
            >
              <div
                id="chatbot-message-color"
                className="w-[25px] h-[25px] rounded-md"
                style={{
                  backgroundColor: tagColor,
                }}
              />
            </div>
          </PopoverTrigger>
          <PopoverContent className="p-0 w-auto">
            <SketchPicker
              color={tagColor}
              onChange={(color: { hex: string }) => {
                setTagColor(color.hex);
              }}
            />
          </PopoverContent>
        </Popover>
        {editTag ? (
          <>
            <Button disabled={!canEditTag} type="submit">
              <Pencil className="h-4 w-4 mr-2" strokeWidth={1.75} />
              Edit tag
            </Button>
            <Button variant="outline" size="icon" onClick={stopEditing}>
              <X className="w-4 h-4" strokeWidth={1.75} />
            </Button>
          </>
        ) : (
          <Button disabled={!canCreateTag || loading} type="submit">
            {loading ? (
              <Loader2 className="w-4 h-4 min-w-[16px] mr-2 animate-spin transition-all" strokeWidth={1.75} />
            ) : (
              <CirclePlus className="h-4 w-4 mr-2" strokeWidth={1.75} />
            )}
            Add Tag
          </Button>
        )}
      </div>
    </form>
  );
};

export default TagForm;
