import { useQuery, useQueryClient } from '@tanstack/react-query';
import sourceTagService from 'api/tag';
import { AddTagToSources, SourceTag, SourceTagList, SourceTagUpdateBody } from 'models/api/response.types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedChatbot } from 'store/reducers/ui';

const useSourceTags = () => {
  const queryClient = useQueryClient();
  const chatbot = useSelector(getSelectedChatbot);
  const queryKey = ['source-tags', chatbot?.uuid];

  const { data } = useQuery({
    queryKey,
    queryFn: () => sourceTagService.getSourceTags(chatbot?.uuid as string),
    enabled: !!chatbot,
  });

  const sourceTags = useMemo(() => {
    if (data) {
      return data.sort((a, b) => b.created_at.localeCompare(a.created_at));
    }
    return undefined;
  }, [data]);

  const addSourceTag = (tag: SourceTag) => {
    const tagList: SourceTagList | undefined = queryClient.getQueryData(queryKey);
    if (tagList) {
      queryClient.setQueryData(queryKey, [tag, ...tagList]);
    } else {
      queryClient.invalidateQueries({ queryKey });
    }
  };

  const updateSourceTag = (payload: { id: string; body: SourceTagUpdateBody }) => {
    const tagList: SourceTagList | undefined = queryClient.getQueryData(queryKey);
    if (tagList) {
      queryClient.setQueryData(
        queryKey,
        tagList.map((tag) => (tag.uuid === payload.id ? { ...tag, ...payload.body } : tag)),
      );
    }
  };

  const updateTagsSourceIds = (payload: AddTagToSources) => {
    const tagList: SourceTagList | undefined = queryClient.getQueryData(queryKey);
    if (tagList) {
      const newList = tagList.map((tag) => {
        if (payload[tag.uuid]) {
          return {
            ...tag,
            data_source_uuids: [...new Set([...tag.data_source_uuids, ...payload[tag.uuid]])],
          };
        }
        return tag;
      });

      queryClient.setQueryData(queryKey, newList);
    }
  };

  const removeSourceTag = (uuid: string) => {
    const tagList: SourceTagList | undefined = queryClient.getQueryData(queryKey);
    if (tagList) {
      queryClient.setQueryData(queryKey, [...tagList.filter((tag) => tag.uuid !== uuid)]);
    }
  };

  const invalidateSourceTagQuery = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  return {
    sourceTags,
    addSourceTag,
    removeSourceTag,
    updateSourceTag,
    invalidateSourceTagQuery,
    updateTagsSourceIds,
    queryKey,
  };
};

export default useSourceTags;
