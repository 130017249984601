import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import subscriptionService from 'api/subscription';
import { getSelectedChatbot } from 'store/reducers/ui';
import { isPrimaryProduct } from 'utils/domain';
import { generateFreePlan } from 'utils/plans';
import { AccountProductToShow, Chatbot, SubscriptionInfo } from 'models/api/response.types';
import { useWhitelabelData } from 'providers/WhiteLabelProvider';
import { findCookieByKey } from 'utils/cookies';
import useUserInfo from './useUserInfo';
import useChatbots from './useChatbots';

const useSubscriptionInfo = (bot?: Chatbot) => {
  const queryClient = useQueryClient();
  const { appDomain, appFreePlanKeys } = useWhitelabelData();
  const { userInfo, userMetaData } = useUserInfo();
  const {
    userActiveChatbots,
    invitesToChatbotsExist,
    chatbotOwnershipTransferExist,
    hasCollaborationChatbots,
  } = useChatbots();
  const selectedChatbot = bot || useSelector(getSelectedChatbot);
  const [isPastDueSubscription, setIsPastDueSubscription] = useState<boolean>(false);
  const [isPastDueAddons, setIsPastDueAddons] = useState<string[]>([]);
  const appSubscriptionQueryKey = ['app-subscription', userInfo?.uuid || ''];
  const accountSubscriptionQueryKey = ['account-plan-info', userInfo?.uuid || ''];
  const chatbotSubscriptionQueryKey = selectedChatbot?.permissions
    ? ['chatbot-plan-info', selectedChatbot?.uuid]
    : accountSubscriptionQueryKey;

  const { data: appSubscriptions } = useQuery({
    queryKey: appSubscriptionQueryKey,
    initialData: undefined,
    enabled: !!userInfo,
    queryFn: () => subscriptionService.getAppProducts(),
  });

  // usage for current account
  const { data: accountSubscription } = useQuery({
    queryKey: accountSubscriptionQueryKey,
    initialData: undefined,
    enabled: !!userInfo,
    queryFn: () => subscriptionService.getAccountSubscriptionInfo(),
  });

  // plans and addons that will be used for showing in upgrade page
  // only active products and products that can be purchased by users in app
  const { plans, addons } = useMemo(() => {
    if (appSubscriptions) {
      const activeProducts = appSubscriptions
        .filter((subscription) => subscription.active) // Filter active subscriptions
        .filter((subscription) => subscription.prices.length > 0) // Filter subscriptions with prices
        .filter((subscription) => !subscription.id.includes('appsumo')) // Filter app_sumo subscriptions
        .map((subscription) => ({
          ...subscription,
          prices: subscription.prices
            .filter((price) => price.active)
            .sort((a, b) => {
              if (!a.recurring && b.recurring) return -1;
              if (a.recurring && !b.recurring) return 1;
              if (a?.recurring?.interval === 'month' && b?.recurring?.interval === 'year') return -1;
              if (a?.recurring?.interval === 'year' && b?.recurring?.interval === 'month') return 1;
              return 0;
            }),
        }))
        .sort((a, b) => a.prices[0].unit_amount - b.prices[0].unit_amount); // sort based on amount

      // businessplan plans are only gpt-trainer allowed, special plans
      return {
        plans: activeProducts.filter(
          (product) =>
            product.unit_label === 'plan' || (product.unit_label === 'businessplan' && isPrimaryProduct),
        ),
        addons: activeProducts.filter((product) => product.unit_label === 'addon'),
      };
    }
    return {
      plans: [],
      addons: [],
    };
  }, [appSubscriptions]);

  // usage for current chatbot
  const { data: chatbotSub } = useQuery({
    queryKey: chatbotSubscriptionQueryKey,
    queryFn: () => {
      if (selectedChatbot?.permissions) {
        return subscriptionService.getChatbotSubscriptionInfo(selectedChatbot.uuid);
      }
      return Promise.resolve(undefined);
    },
    enabled: !!selectedChatbot,
  });

  // if you are not the owner of the chatbot need to use chatbot subscription, otherwise your own
  const chatbotSubscription = selectedChatbot?.permissions ? chatbotSub : accountSubscription;

  const appFreePlan = useMemo(() => {
    if (appFreePlanKeys && userInfo) {
      return generateFreePlan(appFreePlanKeys, userInfo);
    }
    return undefined;
  }, [appFreePlanKeys, userInfo]);

  const { currentAccountPlan, currentAccountAddons } = useMemo(() => {
    if (appSubscriptions && accountSubscription) {
      let currentSubscription: AccountProductToShow = appFreePlan as AccountProductToShow;
      const currentAccountAddonList: AccountProductToShow[] = [];

      accountSubscription.plans.forEach((sub) => {
        const subscription: any = { ...sub };

        appSubscriptions.forEach((plan) => {
          plan.prices.forEach((price) => {
            if (sub.lookup_key === price.lookup_key) {
              subscription.current_plan = plan;
            }
            if (sub.next_key && sub.next_key === price.lookup_key) {
              subscription.next_plan = plan;
            }
          });
        });
        if (subscription?.current_plan) {
          // need to check any plan that has unit_label with plam
          if (subscription?.current_plan.unit_label.includes('plan')) {
            currentSubscription = subscription;
          } else {
            currentAccountAddonList.push(subscription);
          }
        }
      });
      return {
        currentAccountPlan: currentSubscription,
        currentAccountAddons: currentAccountAddonList,
      };
    }
    return {
      currentAccountPlan: undefined,
      currentAccountAddons: [],
    };
  }, [appSubscriptions, accountSubscription, appFreePlan]);

  // check past due susbcriptions
  useEffect(() => {
    if (currentAccountPlan || currentAccountAddons) {
      let showPastDueSubscription = false;
      if (currentAccountPlan?.status === 'past_due') {
        setIsPastDueSubscription(true);
        showPastDueSubscription = true;
      }
      if (currentAccountAddons.length > 0) {
        const pastDueAddons = currentAccountAddons
          .filter((addon) => addon.status === 'past_due')
          .map((addon) => addon.lookup_key);
        if (pastDueAddons.length > 0) {
          setIsPastDueAddons(pastDueAddons);
          showPastDueSubscription = true;
        }
      }
      if (!showPastDueSubscription) {
        const cookie = findCookieByKey(`${appDomain}-subscription-past-due`);
        if (cookie) {
          document.cookie = `${appDomain}-subscription-past-due-${userInfo?.uuid}; expires=${new Date().toUTCString()}; path=/`;
        }
      }
    }
  }, [currentAccountPlan, currentAccountAddons]);

  // account susbcription limits
  const { canUseApiFeature, collaborators, isBringYourOwnKeyEnabled, canTransferChatbotOwnership } =
    useMemo(() => {
      if (accountSubscription) {
        const { api_enabled, collaborators_count, byok_enabled, allow_chatbot_transfer } =
          accountSubscription.usage_limits;
        return {
          canUseApiFeature: api_enabled,
          collaborators: collaborators_count,
          isBringYourOwnKeyEnabled: byok_enabled,
          // currently works only for gpt-trainer and pro plan, hard coding
          canTransferChatbotOwnership: allow_chatbot_transfer,
        };
      }
      return {
        canUseApiFeature: false,
        collaborators: 0,
        canUseZapier: false,
        isBringYourOwnKeyEnabled: false,
        canTransferChatbotOwnership: false,
      };
    }, [accountSubscription, isPastDueSubscription]);

  const { isAppSumoLicense, disabledFeaturesOnTrialCompletion } = useMemo(() => {
    return {
      isAppSumoLicense: currentAccountPlan?.lookup_key.includes('appsumo'),
      disabledFeaturesOnTrialCompletion:
        currentAccountPlan?.status === 'trialing' && !!currentAccountPlan?.trial_completed,
    };
  }, [currentAccountPlan]);

  const canCreateNewChatbots = useMemo(() => {
    if (accountSubscription && userActiveChatbots && !disabledFeaturesOnTrialCompletion) {
      const { chatbots_count } = accountSubscription.usage_limits;
      const userChatbots = userActiveChatbots.filter((chatbot) => !chatbot.permissions);
      return chatbots_count - userChatbots.length > 0;
    }
    return false;
  }, [accountSubscription, userActiveChatbots, disabledFeaturesOnTrialCompletion]);

  const canUpgrade = useMemo(() => {
    const userChatbot = !selectedChatbot?.permissions;
    if (userChatbot && plans.length > 0 && !isAppSumoLicense) {
      const planIndex =
        currentAccountPlan?.lookup_key === 'free_plan'
          ? 0
          : plans.findIndex((plan) =>
              plan.prices.some((price) => price.lookup_key === currentAccountPlan?.lookup_key),
            );
      return currentAccountPlan?.status === 'trialing' || planIndex < plans.length - 1;
    }
    return false;
  }, [selectedChatbot, currentAccountPlan, plans, isAppSumoLicense]);

  const canSeeBilling = useMemo(() => {
    return !!userMetaData?.stripe?.customer_id;
  }, [userMetaData]);

  // disabling screen on trial completion only for current chatbots
  const { mandatorySubscriptionPurchaseEnabled } = useMemo(() => {
    if (currentAccountPlan && userActiveChatbots) {
      const needNavigationToPurchasePage = currentAccountPlan?.trial_date_quantity === 0;
      return {
        mandatorySubscriptionPurchaseEnabled:
          !chatbotOwnershipTransferExist &&
          !hasCollaborationChatbots &&
          needNavigationToPurchasePage &&
          disabledFeaturesOnTrialCompletion &&
          !isPastDueSubscription,
      };
    }
    return {
      mandatorySubscriptionPurchaseEnabled: false,
    };
  }, [
    currentAccountPlan,
    disabledFeaturesOnTrialCompletion,
    invitesToChatbotsExist,
    isPastDueSubscription,
    chatbotSubscription,
    hasCollaborationChatbots,
  ]);

  // chatbot subscription limits
  const {
    canSeeDashboard,
    isGPT4Enabled,
    canUseCustomWatermark,
    canSeeProfilePicture,
    canRemoveGPTMark,
    canUseWebhookIntegrations,
    canUseAutoRetrain,
    canUseGoogleSearch,
    agentsMaxCount,
    agentFunctionsMaxCount,
    canUseCustomDomains,
    canUseWebhooks,
    canUseUserIdentity,
    canCreateHumanEscalationAgent,
    canUseSupervisorSettings,
    agentTagLimit,
    agentVariableLimit,
    canCreateBackgroundAgents,
    canCreateImageGeneratorAgent,
    canUseFacebookApps,
    canUploadImageSources,
    maxAIModelSize,
    crmEnabled,
    slackEnabled,
    sourceTrackingEnabled,
    isChunkViewEnabled,
  } = useMemo(() => {
    const limits: Partial<SubscriptionInfo['usage_limits']> = chatbotSubscription?.usage_limits || {};
    return {
      canSeeDashboard: limits.dashboard ?? false,
      isGPT4Enabled: limits.gpt_4_enabled ?? false,
      canSeeProfilePicture: limits.chatbot_profile_picture ?? false,
      canUseCustomWatermark: limits.custom_watermark ?? false,
      canRemoveGPTMark: limits.watermark_removal ?? false,
      canUseWebhookIntegrations: limits.zapier_integration ?? false,
      canUseAutoRetrain: limits.chatbot_retrain ?? false,
      canUseGoogleSearch: limits.google_search_enabled ?? false,
      agentsMaxCount: limits.agents_count ?? 0,
      agentFunctionsMaxCount: limits.functions_count ?? 0,
      canUseCustomDomains: limits.custom_domain ?? false,
      canUseWebhooks: limits.webhooks ?? false,
      canUseUserIdentity: limits.user_identity_verification ?? false,
      canCreateHumanEscalationAgent: limits.human_escalation_enabled ?? false,
      canUseSupervisorSettings: limits.overrides_enabled ?? false,
      agentTagLimit: limits.agent_tags_count ?? 0,
      agentVariableLimit: limits.agent_variables_count ?? 0,
      canCreateBackgroundAgents: limits.background_agents_enabled ?? false,
      canCreateImageGeneratorAgent: limits.image_generator_enabled ?? false,
      canUseFacebookApps: limits.meta_integration ?? false,
      canUploadImageSources: limits.image_upload_enabled ?? false,
      maxAIModelSize: limits?.max_model_size ?? 10000,
      crmEnabled: limits?.crm_integration ?? false,
      slackEnabled: limits?.slack_integration ?? false,
      sourceTrackingEnabled: limits?.source_tracking_enabled ?? false,
      isChunkViewEnabled: limits?.chunk_view_enabled ?? false,
    };
  }, [chatbotSubscription]);

  const refetchChatbotSubscription = () => {
    queryClient.invalidateQueries({ queryKey: chatbotSubscriptionQueryKey });
  };

  return {
    plans,
    addons,
    isAppSumoLicense,
    appFreePlan,
    appSubscriptions,
    accountSubscription,
    chatbotSubscription,
    accountSubscriptionQueryKey,
    chatbotSubscriptionQueryKey,
    canSeeBilling,
    currentAccountPlan,
    currentAccountAddons,
    isPastDueSubscription,
    isPastDueAddons,
    canUseApiFeature,
    collaborators,
    canCreateNewChatbots,
    canSeeDashboard,
    isGPT4Enabled,
    refetchChatbotSubscription,
    canUseCustomWatermark,
    canRemoveGPTMark,
    canSeeProfilePicture,
    canUseWebhookIntegrations,
    canUseAutoRetrain,
    canUpgrade,
    canUseGoogleSearch,
    agentsMaxCount,
    agentFunctionsMaxCount,
    canUseCustomDomains,
    canUseWebhooks,
    canUseUserIdentity,
    isBringYourOwnKeyEnabled,
    disabledFeaturesOnTrialCompletion,
    mandatorySubscriptionPurchaseEnabled,
    canCreateHumanEscalationAgent,
    canUseSupervisorSettings,
    agentTagLimit,
    agentVariableLimit,
    canCreateBackgroundAgents,
    canTransferChatbotOwnership,
    canCreateImageGeneratorAgent,
    canUseFacebookApps,
    canUploadImageSources,
    maxAIModelSize,
    crmEnabled,
    slackEnabled,
    sourceTrackingEnabled,
    isChunkViewEnabled,
  };
};

export default useSubscriptionInfo;
