/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { Badge } from 'components/ui/badge';
import { Pencil, X } from 'lucide-react';
import { SourceTag, SourceTagUpdateBody } from 'models/api/response.types';
import { useAlerts } from 'providers/AlertProvider';
import { alerts } from 'utils/alert';
import { cn } from 'utils/cn';
import sourceTagService from 'api/tag';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSourceTags from 'hooks/useSourceTags';

const Tag: React.FC<{
  // for adding tags to sources
  canSelect?: boolean;
  selectTag?: () => void;
  selected?: boolean;
  // if sourceId exists, means user want to delete tag from source
  // otherwise user wants to delete tag
  sourceId?: string;
  size?: 'md' | 'default';
  tag: SourceTag;
  showDeleteButton?: boolean;
  showEditButton?: boolean;
  showDocCounter?: boolean;
  onEdit?: () => void;
  editingHiglight?: boolean;
}> = ({
  canSelect,
  selectTag,
  selected,
  sourceId,
  size = 'default',
  tag,
  showDeleteButton,
  showEditButton,
  showDocCounter,
  editingHiglight,
  onEdit,
}) => {
  const queryClient = useQueryClient();
  const { addAlert } = useAlerts();
  const { queryKey, removeSourceTag, updateSourceTag } = useSourceTags();
  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>(null);

  // delete tag entirely
  const deleteSourceTagMutation = useMutation({
    mutationFn: () => sourceTagService.deleteSourceTag(tag.uuid),
    onMutate: () => removeSourceTag(tag.uuid),
    onError: () => {
      addAlert({
        severity: 'error',
        message: alerts.SOMETHING_WRONG,
      });
      queryClient.invalidateQueries({ queryKey });
    },
  });

  // remove source from tag
  const removeTagFromSourceMutation = useMutation({
    mutationFn: (payload: SourceTagUpdateBody) => {
      return sourceTagService.updateSourceTag(tag.uuid, payload);
    },
    onMutate: (payload) => updateSourceTag({ id: tag.uuid, body: payload }),
    onError: () => {
      addAlert({
        severity: 'error',
        message: alerts.SOMETHING_WRONG,
      });
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const handleDeleteClick = () => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      if (sourceId) {
        const { name, color, data_source_uuids } = tag;
        removeTagFromSourceMutation.mutate({
          name,
          color,
          data_source_uuids: data_source_uuids.filter((srcId) => srcId !== sourceId),
        });
      } else {
        deleteSourceTagMutation.mutate();
      }

      setClickTimeout(null);
    } else {
      const timeout = setTimeout(() => {
        addAlert({
          severity: 'info',
          message: sourceId ? alerts.DELETE_SOURCE_TAG : alerts.DELETE_TAG,
        });
        setClickTimeout(null);
      }, 500);
      setClickTimeout(timeout);
    }
  };

  useEffect(() => {
    return () => {
      if (clickTimeout) clearTimeout(clickTimeout);
    };
  }, [clickTimeout]);

  return (
    <Badge
      variant="outline"
      className={cn(
        'h-fit w-fit flex gap-2',
        size === 'md' ? 'px-3 py-2' : 'px-2 py-1',
        editingHiglight && 'border-warning shadow-sm animate-pulse',
        canSelect && 'cursor-pointer',
        canSelect && !selected && 'hover:bg-slate-50',
        selected && 'bg-slate-100',
      )}
      onClick={() => {
        if (canSelect && selectTag) {
          selectTag();
        }
      }}
    >
      <div
        className={cn('rounded-full', size === 'md' ? 'w-3 h-3 min-w-[12px]' : 'w-2 h-2 min-w-[8px]')}
        style={{ background: tag.color }}
      />
      <span
        className={cn(
          'font-medium',
          size === 'md' ? 'text-[14px]' : 'text-xs',
          editingHiglight && 'text-warning',
        )}
      >
        {tag.name}
      </span>
      {showDocCounter && (
        <span className={cn('opacity-95 text-muted-foreground', size === 'md' ? 'text-[13px]' : 'text-xs')}>
          ({tag.data_source_uuids.length})
        </span>
      )}
      {(showEditButton || showDeleteButton) && !editingHiglight && (
        <div className="border-l pl-2 flex items-center gap-2">
          {showEditButton && onEdit && (
            <button onClick={onEdit} className="group" type="button">
              <Pencil
                className={cn(
                  'group-hover:text-foreground transition-colors ',
                  size === 'md' ? 'h-4 w-4' : 'h-3 w-3',
                )}
              />
            </button>
          )}
          {showDeleteButton && (
            <button onClick={handleDeleteClick} className="group" type="button">
              <X
                className={cn(
                  'group-hover:text-destructive transition-colors ',
                  size === 'md' ? 'h-4 w-4' : 'h-3 w-3',
                )}
              />
            </button>
          )}
        </div>
      )}
    </Badge>
  );
};

export default Tag;
