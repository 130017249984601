import { Button } from 'components/ui/button';
import { Separator } from 'components/ui/separator';
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';
import { FunctionSquare, GraduationCap, Loader2, MessageSquareDot, Settings2, Tags, X } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useAlerts } from 'providers/AlertProvider';
import { AIAgent, AIAgentEditData, AIAgentUpdateBody } from 'models/api/response.types';
import { functionCallingDisabledModels, aiModelCharacterLimit } from 'utils/agent';
import agentService from 'api/agent';
import { cn } from 'utils/cn';
import useAiAgents from 'hooks/useAiAgents';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { alerts } from 'utils/alert';
import ConfirmRetrainDialog from 'components/Dialogs/Agents/ConfirmRetrainDialog';
import GeneralTab from './GeneralTab/GeneralTab';
import FunctionsTab from './FunctionsTab/FunctionsTab';
import KnowledgeTab from './KnowledgeTab/KnowledgeTab';
import VariablesTagsTab from './VariablesTagsTab/VariablesTagsTab';
import HumanEscalationMonitoringTab from './GeneralTab/HumanEscalationMonitoringTab';

// 1. general - all agents
// 2. knowledge - user-facing agents
// 3. functions - background/user-facing agents
// 4. variables-tags - background/user-facing agents
// 5. human_escalation_monitoring - human-escalation gents
type Tab = 'general' | 'knowledge' | 'functions' | 'variables-tags' | 'human_escalation_monitoring';

const AgentData: React.FC<{
  agent?: AIAgent;
  hide: () => void;
}> = ({ agent, hide }) => {
  const { addAlert } = useAlerts();
  const { canUseSupervisorSettings } = useSubscriptionInfo();
  const { updateAgent: updateSelectedAgent } = useAiAgents();
  const [show, setShow] = useState<boolean>(false);
  const [agentSourceIds, setAgentSourceIds] = useState<string[]>([]);
  const [generalData, setGeneralData] = useState<AIAgentEditData | undefined>(undefined);
  const [initialGeneralData, setInitialGeneralData] = useState<string>('');
  const [initialAgentSourceIds, setInitialAgentSourceIds] = useState<string>('');
  const [canUpdateGeneralData, setCanUpdateGeneralData] = useState<boolean>(false);
  const [canUpdateSourceIds, setCanUpdateSourceIds] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [openConfirmRetrainDialog, setOpenConfirmRetrainDialog] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<Tab>('general');
  const [supportedMultiTabAgents] = useState<AIAgent['type'][]>([
    'background',
    'human-escalation',
    'user-facing',
  ]);

  const close = () => {
    setShow(false);
    setTimeout(() => {
      hide();
      setCurrentTab('general');
    }, 300);
  };

  const canUpdate = useMemo(() => {
    return canUpdateGeneralData || canUpdateSourceIds;
  }, [[canUpdateGeneralData, canUpdateSourceIds]]);

  const triggerNewTab = (tab: Tab) => {
    if (canUpdate) {
      addAlert({
        severity: 'error',
        message: alerts.AGENT_SAVE_CHANGES_ERROR,
        timeout: 5000,
      });
    } else {
      setCurrentTab(tab);
    }
  };

  useEffect(() => {
    if (JSON.stringify(generalData) !== initialGeneralData) {
      setCanUpdateGeneralData(true);
    } else if (canUpdateGeneralData) {
      setCanUpdateGeneralData(false);
    }

    if (JSON.stringify(agentSourceIds) !== initialAgentSourceIds) {
      setCanUpdateSourceIds(true);
    } else if (canUpdateSourceIds) {
      setCanUpdateSourceIds(false);
    }
  }, [generalData, agentSourceIds]);

  const setAgentData = (ag: AIAgent) => {
    setInitialAgentSourceIds(JSON.stringify(ag.data_source_uuids));
    setAgentSourceIds(ag.data_source_uuids);

    const { name, description, prompt, meta_json, variables_json, type, enabled, llm_platforms } = ag;
    const meta = JSON.parse(meta_json);
    const variables = JSON.parse(variables_json);
    const initialData = {
      name,
      description,
      prompt,
      type,
      enabled,
      meta,
      ...(['user-facing', 'background'].includes(type) && {
        llm_platforms,
      }),
      ...(type === 'human-escalation' && {
        human_escalation_settings: ag.human_escalation_settings,
      }),
      ...(type === 'image-generator' && {
        image_generator_settings: ag.image_generator_settings,
      }),
      ...(variables && {
        variables,
      }),
    };

    setGeneralData(initialData);
    setInitialGeneralData(JSON.stringify(initialData));
  };

  useEffect(() => {
    if (agent) {
      setAgentData(agent);
      setShow(true);
    }
  }, [agent]);

  const isValidAgent = () => {
    if (generalData) {
      const { name, description, prompt, meta, human_escalation_settings } = generalData;
      if (
        name.length === 0 ||
        description.length === 0 ||
        (['background', 'user-facing'].includes(agent?.type || '') &&
          (prompt.length === 0 || aiModelCharacterLimit[meta.model] < prompt.length)) ||
        (!!human_escalation_settings && !human_escalation_settings.human_requested_message) ||
        (['pre-canned', 'spam-defense'].includes(agent?.type || '') && !meta.default_message) ||
        (!!meta?.context_guard && !meta?.context_guard_message)
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  const resetChanges = () => {
    setGeneralData(JSON.parse(initialGeneralData));
    setAgentSourceIds(JSON.parse(initialAgentSourceIds));
  };

  const updateAgent = (body: AIAgentUpdateBody, triggerRetrainDialog: boolean) => {
    if (agent) {
      setSaving(true);
      agentService
        .updateAIAgent(agent.uuid, body)
        .then((res: AIAgent) => {
          updateSelectedAgent(res);
          setAgentData(res);
          if (triggerRetrainDialog) {
            setOpenConfirmRetrainDialog(true);
          } else {
            addAlert({
              severity: 'success',
              message: alerts.AGENT_UPDATE,
              timeout: 5000,
            });
          }
          setSaving(false);
        })
        .catch(() => {
          addAlert({
            severity: 'error',
            message: alerts.SOMETHING_WRONG,
            timeout: 5000,
          });
          setSaving(false);
        });
    }
  };

  const triggerAgentEditing = () => {
    if (isValidAgent() && generalData && agentSourceIds) {
      const initialParsedData: AIAgentEditData = JSON.parse(initialGeneralData);
      const { meta, variables, name, description, prompt } = generalData;
      // changes of prompt, description and name triggers agent retrain
      // automatic retrains for users without supervisor settings
      // otherwise dialog with confirmation
      const needToRetrain =
        initialParsedData.name !== name ||
        initialParsedData.description !== description ||
        (!!prompt && initialParsedData.prompt !== prompt);
      const triggerRetrainDialog = needToRetrain && canUseSupervisorSettings;
      const dataToUpdate = {
        ...(canUpdateSourceIds && { data_source_uuids: agentSourceIds }),
        ...(canUpdateGeneralData && {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          ...(({ meta, variables, ...rest }) => rest)(generalData),
          retrain: needToRetrain && !canUseSupervisorSettings,
          ...(meta && {
            meta_json: JSON.stringify(meta),
          }),
          ...(variables && {
            variables_json: JSON.stringify(variables),
          }),
        }),
      };
      updateAgent(dataToUpdate, triggerRetrainDialog);
    } else {
      addAlert({
        severity: 'error',
        message: alerts.AGENT_SAVE_CHANGES_CREATE_ERROR,
        timeout: 5000,
      });
    }
  };

  if (!agent || !generalData) {
    return null;
  }

  return (
    <div
      className={cn(
        'z-[3] bg-background absolute left-0 top-0 h-full border-r shadow-sm flex flex-col transition-all duration-300 ease-out xl:w-[800px] overflow-hidden agent-form',
        show ? 'translate-x-[0px]' : '-translate-x-[120%]',
      )}
    >
      <div className="flex items-center text-lg font-semibold text-foreground px-6 pt-4">
        <h1>{agent.name}</h1>
        {canUpdate && (
          <>
            <Button disabled={saving} variant="outline" onClick={resetChanges} className="ml-auto">
              Reset
            </Button>
            <Button disabled={saving} onClick={triggerAgentEditing} className="ml-4">
              {saving && <Loader2 className="w-4 h-4 mr-2 animate-spin" />}
              Save changes
            </Button>
          </>
        )}
        <Button
          id="agent-form-close-button"
          variant="outline"
          type="button"
          disabled={saving}
          onClick={close}
          className={cn(
            'rounded-sm border p-1 px-2 opacity-70 ring-offset-background transition-opacity hover:opacity-100 ',
            canUpdate ? 'ml-4' : 'ml-auto',
          )}
        >
          <X strokeWidth={1.75} className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      </div>
      <Separator className="my-4" />
      <Tabs
        value={supportedMultiTabAgents.includes(agent.type) ? currentTab : 'general'}
        className="flex-1 flex flex-col overflow-auto px-6 pb-4"
      >
        {supportedMultiTabAgents.includes(agent.type) && (
          <TabsList
            className={cn(
              'grid w-full h-auto mb-4',
              agent.type === 'user-facing' && 'grid-cols-2 sm:grid-cols-4',
              agent.type === 'human-escalation' && 'grid-cols-2',
              agent.type === 'background' && 'grid-cols-2 sm:grid-cols-3',
            )}
          >
            <TabsTrigger
              value="general"
              onClick={() => {
                triggerNewTab('general');
              }}
            >
              <div className="relative flex items-center">
                <Settings2 strokeWidth={1.75} className="h-4 w-4 min-w-[16px] mr-2" />
                General
              </div>
            </TabsTrigger>
            {agent.type !== 'human-escalation' ? (
              <>
                <TabsTrigger
                  value="variables-tags"
                  onClick={() => {
                    triggerNewTab('variables-tags');
                  }}
                >
                  <div className="relative flex items-center">
                    <Tags strokeWidth={1.75} className="h-4 w-4 min-w-[16px] mr-2" />
                    {agent.type === 'background' ? 'Tags' : 'Variables'}
                  </div>
                </TabsTrigger>
                {agent.type === 'user-facing' && (
                  <TabsTrigger value="knowledge" onClick={() => triggerNewTab('knowledge')}>
                    <GraduationCap strokeWidth={1.75} className="h-4 w-4 min-w-[16px] mr-2" />
                    Knowledge
                  </TabsTrigger>
                )}
                <TabsTrigger value="functions" onClick={() => triggerNewTab('functions')}>
                  <FunctionSquare strokeWidth={1.75} className="h-4 w-4 min-w-[16px] mr-2" />
                  Functions
                </TabsTrigger>
              </>
            ) : (
              <TabsTrigger
                value="human_escalation_monitoring"
                onClick={() => {
                  triggerNewTab('human_escalation_monitoring');
                }}
              >
                <div className="relative flex items-center">
                  <MessageSquareDot strokeWidth={1.75} className="h-4 w-4 min-w-[16px] mr-2" />
                  Monitoring
                </div>
              </TabsTrigger>
            )}
          </TabsList>
        )}
        <GeneralTab data={generalData} setData={setGeneralData} />
        <HumanEscalationMonitoringTab data={generalData} setData={setGeneralData} />
        <VariablesTagsTab agentId={agent.uuid} data={generalData} setData={setGeneralData} />
        <FunctionsTab
          modelDisabled={functionCallingDisabledModels.includes(generalData?.meta?.model)}
          agentId={agent.uuid}
          agentFunctions={agent.tool_functions}
        />
        <KnowledgeTab
          data={generalData}
          setData={setGeneralData}
          sourceIds={agentSourceIds}
          setSourceIds={setAgentSourceIds}
        />
      </Tabs>
      <ConfirmRetrainDialog
        agentId={agent.uuid}
        show={openConfirmRetrainDialog}
        close={() => setOpenConfirmRetrainDialog(false)}
      />
    </div>
  );
};

export default AgentData;
