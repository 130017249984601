/* eslint-disable no-prototype-builtins */
import { DateRange } from 'react-day-picker';
import { ChatBotMeta } from './bot';

type SessionOrigin =
  | 'whatsapp'
  | 'messenger'
  | 'instagram'
  | 'link'
  | 'preview'
  | 'make'
  | 'zapier'
  | 'widget-iframe'
  | 'api';

export interface ParsedSessionMeta {
  // when user starts session
  // doesn't exist in new session after Aug13 2024
  initial_message?: string;
  // latest message in inbox
  latest_message?: string;
  // if hosted in other domain
  parent_domain?: string;
  // disclaimer accepted by user
  disclaimer_accepted_at?: string;
  // default chatbot meta at that specific time when session was created
  chatbot_meta: ChatBotMeta;
  // if admin read the session
  read: boolean;
  // where session came from
  origin: SessionOrigin;
  // when user submitted form data
  form_data?: {
    [key: string]: any;
  };
  // valid response from user identity verification call
  auth_data?: {
    [key: string]: any;
  };
  // integration details
  whatsapp_details?: {
    chatbot: { phone_number: string };
    user: { phone_number: string };
  };
  messenger_details?: {
    chatbot: { page_id: string };
    user: { name: string; profile_pic: string };
  };
  instagram_details?: {
    chatbot: { instagram_id: string };
    user: {
      name: string;
      username: string;
      follower_count: number;
      is_user_follow_business: boolean;
      is_business_follow_user: boolean;
    };
  };
  zendesk_details?: {
    chatbot: {
      zendesk_app_id: string;
      zendesk_conversation_id: string;
    };
  };
}

export const convertSessionsDate = (date: DateRange) => {
  const originalDateFrom = new Date(date.from as Date);
  const originalDateTo = date.to ? new Date(date.to as Date) : originalDateFrom;

  const yearFrom = originalDateFrom.getFullYear();
  const monthFrom = originalDateFrom.getMonth();
  const dayFrom = originalDateFrom.getDate();

  const yearTo = originalDateTo.getFullYear();
  const monthTo = originalDateTo.getMonth();
  const dayTo = originalDateTo.getDate();

  const newDateFrom = new Date(yearFrom, monthFrom, dayFrom, 0, 0, 0);
  const newDateTo = new Date(yearTo, monthTo, dayTo, 23, 59, 59);

  return {
    from: newDateFrom.toISOString(),
    to: newDateTo.toISOString(),
  };
};

export const isSessionStorageAvailable = () => {
  try {
    sessionStorage.setItem('test', 'test');
    sessionStorage.removeItem('test');
    return true;
  } catch (error) {
    return false;
  }
};

export const isValidJson = (data: any): boolean => {
  let obj;

  // Attempt to handle and parse if data is a string
  if (typeof data === 'string') {
    try {
      obj = JSON.parse(data);
    } catch (error) {
      return false; // not valid JSON string
    }
  } else {
    obj = data; // Assume it's already an object and assign directly
  }

  // Check if it's an object (and not null or an array)
  if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {
    return false;
  }

  // Check if the object is not empty
  return Object.keys(obj).length > 0;
};
