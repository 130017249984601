/* eslint-disable no-new */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import { cn } from 'utils/cn';
import {
  Boxes,
  Braces,
  Cable,
  CloudUpload,
  Database,
  Download,
  FolderSync,
  GanttChartSquare,
  Hash,
  Loader2,
  Plus,
  RefreshCw,
  Tags,
  Trash2,
  X,
} from 'lucide-react';
import { Badge } from 'components/ui/badge';
import { useAlerts } from 'providers/AlertProvider';
import { alerts } from 'utils/alert';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';
import { DataRow, sourceTypes } from 'utils/sources';
import { formatBytes, numberWithDots } from 'utils/formatting';
import { Skeleton } from 'components/ui/skeleton';
import { format } from 'date-fns';
import { Label } from 'components/ui/label';
import { Switch } from 'components/ui/switch';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import sourceService from 'api/source';
import useDataSources from 'hooks/useDataSources';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import { downloadDataSource, downloadQASource } from 'utils/dataSources';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { Chatbot, ReTrainFrequency } from 'models/api/response.types';
import { getSelectedChatbot } from 'store/reducers/ui';
import { useSelector } from 'react-redux';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';
import SourceTableDataDialog from 'components/Dialogs/Sources/SourceTableDataDialog/SourceTableDataDialog';
import EditQASourceDialog from 'components/Dialogs/Sources/EditQASourceDialog';
import { createPortal } from 'react-dom';
import SourceChunksDialog from 'components/Dialogs/Sources/SourceChunksDialog';
import SubscriptionLimitsExceededDialog from 'components/Dialogs/Subscription/SubscriptionLimitsExceededDialog';
import VideoPlayer from 'components/helpers/VideoPlayer';
import useSourceTags from 'hooks/useSourceTags';
import { EventBus } from 'utils/eventBus';
import SourceStatus from './SourceStatus';
import Tag from '../TagManager/Tag';

export const sourceRetrainLoadingStatuses = [
  'assigned',
  'await',
  'embedding',
  'running',
  'chunking',
  'downloading',
];
export const sourceRetrainSubscriptionErrors = ['error:storage', 'error:token'];
export const sourceRetrainErrorStatuses = [
  'error',
  'fail',
  'error:no text',
  'error:download',
  ...sourceRetrainSubscriptionErrors,
];
export const sourceRetrainFinalStates = [...sourceRetrainErrorStatuses, 'success', null, '0'];

const SourceDetailsDrawer: React.FC<{
  source?: DataRow;
  retryDataSource: (id: string) => void;
  close: () => void;
  deleteSource: () => void;
  retrainUrl: (id: string) => void;
}> = ({ source, close, retryDataSource, deleteSource, retrainUrl }) => {
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const { updateSource } = useDataSources();
  const { sourceTags } = useSourceTags();
  const { canUseAutoRetrain, isChunkViewEnabled } = useSubscriptionInfo();
  const { addAlert } = useAlerts();
  const [show, setShow] = useState<boolean>(false);
  const [sourceData, setSourceData] = useState<any>(undefined);
  const [updating, setUpdating] = useState<boolean>(false);
  const [downloadingFile, setDownloadingFile] = useState<boolean>(false);
  const [retrainingClicked, setRetrainingClicked] = useState<boolean>(false);
  const [settingAutoRetrain, setSettingAutoRetrain] = useState<boolean>(false);
  const [openTableData, setOpenTableData] = useState<boolean>(false);
  const [openQAData, setOpenQAData] = useState<boolean>(false);
  const [openSourceChunks, setOpenSourceChunks] = useState<boolean>(false);
  const [showLimitExceededDialog, setShowLimitExceededDialog] = useState<boolean>(false);
  const [accordionActiveTabs, setAccordionActiveTabs] = useState(['details', 'citation-data']);

  const { originalSourceMeta, isSourceSucceeded, isSubscriptionError, isFile, isLoading, isUrlSource } =
    useMemo(() => {
      return {
        isLoading: sourceRetrainLoadingStatuses.includes(source?.retrain_status || ''),
        originalSourceMeta: JSON.parse(source?.meta_json || '{}'),
        isSubscriptionError: sourceRetrainSubscriptionErrors.includes(source?.retrain_status || ''),
        isSourceSucceeded: source?.status === 'success',
        isUrlSource: source?.type === 'url',
        isVideoSource: source?.type === 'video',
        isFile: ['upload', 'google-drive', 'table'].includes(source?.type || ''),
      };
    }, [source]);

  const documentTags = useMemo(() => {
    if (source && sourceTags) {
      return sourceTags?.filter((tag) => tag.data_source_uuids.includes(source?.uuid));
    }
    return [];
  }, [sourceTags, source]);

  useEffect(() => {
    // hide citation and retrain tabs if they not allowed
    setAccordionActiveTabs(
      [...accordionActiveTabs].filter((v) => {
        if (
          (!isSourceSucceeded && ['citation-data', 'retrain-url'].includes(v)) ||
          (!canUseAutoRetrain && v === 'retrain-url')
        ) {
          return false;
        }
        return true;
      }),
    );
  }, [isSourceSucceeded, canUseAutoRetrain]);

  useEffect(() => {
    if (retrainingClicked && isSubscriptionError && !isLoading) {
      addAlert({
        severity: 'error',
        message: alerts.URL_SUBSCRIPTION_LIMIT,
      });
      setRetrainingClicked(false);
    }
  }, [retrainingClicked, isSubscriptionError, isLoading]);

  useEffect(() => {
    if (source) {
      const meta = JSON.parse(source.meta_json);
      setSourceData({
        ...source,
        meta_json: meta,
      });
    }
  }, [source?.meta_json]);

  useEffect(() => {
    if (source && !show) {
      setShow(true);
    }
  }, [source]);

  const isValidURL = (websiteUrl: string) => {
    try {
      new URL(websiteUrl);
      return true;
    } catch (error) {
      return false;
    }
  };

  const isValidCitationLink = useMemo(() => {
    const link = sourceData?.meta_json?.reference_source_link;
    return !link || isValidURL(link);
  }, [sourceData]);

  const canUpdate = useMemo(() => {
    if (
      !updating &&
      ((sourceData?.title !== source?.title && sourceData?.title?.length > 0) ||
        (sourceData?.meta_json?.reference_source_link !== originalSourceMeta.reference_source_link &&
          isValidCitationLink) ||
        sourceData?.meta_json?.enable_citing !== originalSourceMeta.enable_citing)
    ) {
      return true;
    }
    return false;
  }, [updating, sourceData, sourceData?.meta_json, isValidCitationLink, source, source?.meta_json]);

  const updateProperties = () => {
    setUpdating(true);
    if (source) {
      sourceService
        .updateDataSource(source.uuid, {
          title: sourceData?.title || '',
          meta_json: JSON.stringify(sourceData?.meta_json),
        })
        .then(() => {
          updateSource({
            ...sourceData,
            meta_json: JSON.stringify(sourceData?.meta_json),
          });
        })
        .finally(() => {
          setUpdating(false);
        });
    }
  };

  const setAutoRetrain = () => {
    if (source) {
      setSettingAutoRetrain(true);
      sourceService
        .updateBatchDataSources(chatbot.uuid, {
          uuids: [source?.uuid],
          updates: { retrain_frequency: sourceData?.meta_json?.retrain_frequency },
        })
        .then(() => {
          updateSource({
            ...source,
            meta_json: JSON.stringify(sourceData?.meta_json),
          });
        })
        .catch(() => {
          addAlert({
            severity: 'error',
            message: alerts.SOMETHING_WRONG,
          });
        })
        .finally(() => {
          setSettingAutoRetrain(false);
        });
    }
  };

  const hide = () => {
    setShow(false);
    setTimeout(() => {
      close();
    }, 300);
  };

  return (
    <>
      <div
        className={cn(
          'z-[2] absolute 2xl:relative w-full 2xl:w-auto  xl:translate-x-[0px] right-0 top-0 h-full transition-all duration-300 overflow-hidden ease-out md:max-w-[450px] 2xl:max-w-[400px] 3xl:max-w-[500px]',
          show ? 'translate-x-[0px] opacity-100 2xl:w-full' : 'translate-x-[120%] 2xl:w-0  opacity-0',
        )}
      >
        <div className="bg-background border rounded-l-md md:rounded-l-none rounded-r-md shadow-sm border-l h-full overflow-hidden flex flex-col">
          <div className="flex items-center text-base font-semibold text-foreground px-6 h-[48px] border-b">
            <h1>Source Properties</h1>
            <div className="flex items-center gap-4 ml-auto">
              {isSourceSucceeded && source && (
                <>
                  <TooltipProvider>
                    <Tooltip className="h-full">
                      <TooltipTrigger asChild>
                        <Button
                          onClick={() => {
                            // if chunk view feature disabled, we should show upgrade dialog for users
                            // chunk view works for all sources except tables and qa's
                            if (!isChunkViewEnabled && !['table', 'qa'].includes(source.type)) {
                              setShowLimitExceededDialog(true);
                            } else if (source.type === 'table') {
                              setOpenTableData(true);
                            } else if (source.type === 'qa') {
                              setOpenQAData(true);
                            } else {
                              setOpenSourceChunks(true);
                            }
                          }}
                          variant="outline"
                          className="flex p-[2px] h-auto"
                        >
                          <GanttChartSquare strokeWidth={1.75} className="h-4 w-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent className="font-normal" side="bottom" align="end">
                        View Content
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  {source.type === 'url' && (
                    <TooltipProvider>
                      <Tooltip className="h-full">
                        <TooltipTrigger asChild>
                          <Button
                            onClick={() => {
                              setRetrainingClicked(true);
                              retrainUrl(source.uuid);
                            }}
                            variant="outline"
                            className="flex p-[2px] h-auto"
                          >
                            {isLoading ? (
                              <Loader2 strokeWidth={1.75} className="h-4 w-4 animate-spin" />
                            ) : (
                              <RefreshCw strokeWidth={1.75} className="h-4 w-4" />
                            )}
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent className="font-normal" side="bottom" align="end">
                          Re-train URL
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                  {(isFile || source.type === 'qa') && (
                    <TooltipProvider>
                      <Tooltip className="h-full">
                        <TooltipTrigger asChild>
                          <Button
                            onClick={() => {
                              if (isFile) {
                                setDownloadingFile(true);
                                addAlert({
                                  severity: 'info',
                                  message: alerts.FILE_DOWNLOAD_LOAD(source.file_name),
                                });
                                downloadDataSource(
                                  { file_name: source.file_name, uuids: [source.uuid] },
                                  (success: boolean) => {
                                    setDownloadingFile(false);
                                    if (success) {
                                      addAlert({
                                        severity: 'success',
                                        message: alerts.FILE_DOWNLOAD_SUCCESS(source.file_name),
                                      });
                                    } else {
                                      addAlert({
                                        severity: 'error',
                                        message: alerts.FILE_DONWLOAD_ERROR(source.file_name),
                                      });
                                    }
                                  },
                                );
                              } else {
                                downloadQASource(source);
                              }
                            }}
                            disabled={downloadingFile}
                            variant="outline"
                            className="flex p-[2px] h-auto"
                          >
                            {downloadingFile ? (
                              <Loader2 strokeWidth={1.75} className="h-4 w-4 animate-spin" />
                            ) : (
                              <Download strokeWidth={1.75} className="h-4 w-4" />
                            )}
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent className="font-normal" side="bottom" align="end">
                          Download File
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </>
              )}
              <TooltipProvider>
                <Tooltip className="h-full">
                  <TooltipTrigger asChild>
                    <Button
                      onClick={deleteSource}
                      variant="outline"
                      className="flex p-[2px] h-auto hover:text-destructive hover:bg-destructive/10"
                    >
                      <Trash2 strokeWidth={1.75} className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="font-normal" side="bottom" align="end">
                    Delete Source
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <button
              type="button"
              onClick={hide}
              className="rounded-sm border p-[2px] opacity-70 ring-offset-background transition-opacity hover:opacity-100 ml-4"
            >
              <X strokeWidth={1.75} className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </button>
          </div>
          {source && sourceData && (
            <div className="flex-1 overflow-y-auto overflow-x-hidden">
              {source?.media_link && (
                <div className="p-4">
                  {source.type === 'video' ? (
                    <div className="rounded-lg border shadow-sm">
                      <VideoPlayer className="m-0" src={source.media_link} />
                    </div>
                  ) : (
                    <img
                      className="w-auto max-w-full h-auto object-contain rounded-lg border shadow-sm mx-auto"
                      src={source.media_link}
                      alt={source?.title || 'Source snippet'}
                    />
                  )}
                </div>
              )}
              <Accordion value={accordionActiveTabs} type="multiple">
                <AccordionItem value="details">
                  <AccordionTrigger
                    onClick={() => {
                      if (accordionActiveTabs.includes('details')) {
                        setAccordionActiveTabs([...accordionActiveTabs].filter((v) => v !== 'details'));
                      } else {
                        setAccordionActiveTabs([...accordionActiveTabs, 'details']);
                      }
                    }}
                    className="py-2 px-6 bg-muted/30 hover:no-underline uppercase text-muted-foreground text-xs border-t"
                  >
                    Details
                  </AccordionTrigger>
                  <AccordionContent className="border-t px-6 py-4 flex flex-col gap-2">
                    <p
                      onClick={() => {
                        if ('clipboard' in navigator) {
                          navigator.clipboard.writeText(source.uuid);
                          addAlert({
                            severity: 'success',
                            message: alerts.SESSION_UUID_COPIED,
                          });
                        }
                      }}
                      className="text-sm flex items-center gap-4 text-muted-foreground whitespace-nowrap cursor-pointer overflow-hidden"
                    >
                      <Hash className="w-4 h-4 min-w-[16px]" strokeWidth={1.75} />
                      UUID
                      <span className="text-foreground ml-auto font-medium text-ellipsis whitespace-nowrap overflow-hidden">
                        {source.uuid}
                      </span>
                    </p>
                    <div className="text-sm flex items-center gap-4 text-muted-foreground whitespace-nowrap">
                      <Boxes className="w-4 h-4" strokeWidth={1.75} />
                      Type
                      <Badge variant="outline" className="bg-background ml-auto rounded-md text-sm">
                        {sourceTypes[source.type].icon()}
                        {sourceTypes[source.type].title}
                      </Badge>
                    </div>
                    <div className="text-sm flex items-center gap-4 text-muted-foreground whitespace-nowrap">
                      <Braces className="w-4 h-4" strokeWidth={1.75} />
                      Tokens
                      {source.tokens > -1 ? (
                        <span
                          className={cn(
                            'ml-auto font-medium text-ellipsis whitespace-nowrap overflow-hidden',
                            source.status === 'error:token' && !source.canRetry
                              ? 'text-destructive'
                              : 'text-foreground',
                          )}
                        >
                          {numberWithDots(source.tokens)}
                        </span>
                      ) : (
                        <Skeleton className="w-[100px] h-[24px] ml-auto" />
                      )}
                    </div>
                    <div className="text-sm flex items-center gap-4 text-muted-foreground whitespace-nowrap">
                      <Database className="w-4 h-4" strokeWidth={1.75} />
                      Size
                      {source.file_size > -1 ? (
                        <span
                          className={cn(
                            'ml-auto font-medium text-ellipsis whitespace-nowrap overflow-hidden',
                            source.status === 'error:storage' && !source.canRetry
                              ? 'text-destructive'
                              : 'text-foreground',
                          )}
                        >
                          {formatBytes(source.file_size)}
                        </span>
                      ) : (
                        <Skeleton className="w-[100px] h-[24px] ml-auto" />
                      )}
                    </div>
                    <div className="text-sm flex items-center gap-4 text-muted-foreground whitespace-nowrap">
                      <Cable className="w-4 h-4" strokeWidth={1.75} />
                      Status
                      <div className="ml-auto text-foreground">
                        <SourceStatus source={source} retryDataSource={retryDataSource} />
                      </div>
                    </div>
                    <div className="text-sm flex items-start gap-4 text-muted-foreground whitespace-nowrap">
                      <div className="flex items-center gap-4">
                        <Tags className="w-4 h-4" strokeWidth={1.75} />
                        Tags
                        <Button
                          onClick={() => {
                            EventBus.emit('add-multi-source-tag', [source.uuid]);
                          }}
                          variant="outline"
                          size="icon"
                          className="rounded-full p-[2px] h-5 w-5"
                        >
                          <Plus className="w-3 h-3" />
                        </Button>
                      </div>
                      <div className="ml-auto text-foreground">
                        {documentTags.length ? (
                          <div className="flex flex-wrap gap-1 items-center justify-end">
                            {documentTags.map((tag) => (
                              <Tag showDeleteButton sourceId={source.uuid} key={tag.uuid} tag={tag} />
                            ))}
                          </div>
                        ) : (
                          <span className="text-sm text-muted-foreground italic">No tags</span>
                        )}
                      </div>
                    </div>
                    <div className="text-sm flex items-center gap-4 text-muted-foreground whitespace-nowrap">
                      <FolderSync className="w-4 h-4" strokeWidth={1.75} />
                      Last Trained On
                      <span className="text-foreground ml-auto font-medium text-ellipsis whitespace-nowrap overflow-hidden">
                        {format(new Date(source.modified_at), 'yyyy-MM-dd HH:mm')}
                      </span>
                    </div>
                    <div className="text-sm flex items-center gap-4 text-muted-foreground whitespace-nowrap">
                      <CloudUpload className="w-4 h-4" strokeWidth={1.75} />
                      Added At
                      <span className="text-foreground ml-auto font-medium text-ellipsis whitespace-nowrap overflow-hidden">
                        {format(new Date(source.created_at), 'yyyy-MM-dd HH:mm')}
                      </span>
                    </div>
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem disabled={!isSourceSucceeded} value="citation-data">
                  <AccordionTrigger
                    onClick={() => {
                      if (accordionActiveTabs.includes('citation-data')) {
                        setAccordionActiveTabs([...accordionActiveTabs].filter((v) => v !== 'citation-data'));
                      } else {
                        setAccordionActiveTabs([...accordionActiveTabs, 'citation-data']);
                      }
                    }}
                    className="py-2 px-6 bg-muted/30 hover:no-underline uppercase text-muted-foreground text-xs"
                  >
                    Citation Data
                  </AccordionTrigger>
                  <AccordionContent className="border-t px-6 py-4 flex flex-col gap-2">
                    <div className="flex flex-col gap-2">
                      <Label
                        className="text-sm font-medium leading-none tracking-tight"
                        htmlFor="use_as_citation"
                      >
                        Citation Reference
                      </Label>
                      <p className="text-xs text-muted-foreground">
                        When enabled, the AI will cite the selected source in its responses. If the feature is
                        disabled, the AI will still use the source for information but will not display the
                        citation.
                      </p>
                      <Switch
                        id="use_as_citation"
                        checked={
                          sourceData?.meta_json?.enable_citing !== undefined
                            ? sourceData?.meta_json?.enable_citing
                            : true
                        }
                        onCheckedChange={(allow) => {
                          setSourceData({
                            ...sourceData,
                            meta_json: {
                              ...sourceData.meta_json,
                              enable_citing: allow,
                            },
                          });
                        }}
                      />
                    </div>
                    <p className="font-normal text-muted-foreground text-xs mt-4">
                      Control how source titles appear to users and customize source links for direct access
                      to specific webpages or online resources.
                    </p>
                    <div className="relative">
                      <Label className="text-sm" htmlFor="title">
                        Title
                      </Label>
                      <Input
                        id="title"
                        type="text"
                        value={sourceData?.title || ''}
                        onChange={(e) => {
                          setSourceData({
                            ...sourceData,
                            title: e.target.value,
                          });
                        }}
                        className="pr-12"
                      />
                      {source?.title !== sourceData?.title && (
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={() => {
                            setSourceData({
                              ...sourceData,
                              title: source?.title,
                            });
                          }}
                          type="button"
                          className="h-7 w-7 absolute right-0 top-7 mr-2"
                        >
                          <X className="h-4 w-4 min-w-[16px]" strokeWidth={1.75} />
                        </Button>
                      )}
                      {source?.title !== sourceData?.title && sourceData?.title.length === 0 && (
                        <p className="text-xs text-destructive ml-1 mt-1">Title can&apos;t be empty.</p>
                      )}
                    </div>
                    <div className="mt-2 relative">
                      <Label className="text-sm" htmlFor="link">
                        Link
                      </Label>
                      <Input
                        id="message-limit"
                        type="text"
                        value={sourceData?.meta_json?.reference_source_link || ''}
                        className="pr-12"
                        onChange={(e) => {
                          setSourceData({
                            ...sourceData,
                            meta_json: {
                              ...sourceData.meta_json,
                              reference_source_link: e.target.value,
                            },
                          });
                        }}
                      />
                      {originalSourceMeta?.reference_source_link !==
                        sourceData?.meta_json?.reference_source_link &&
                        !!sourceData?.meta_json?.reference_source_link?.length && (
                          <Button
                            size="sm"
                            variant="ghost"
                            onClick={() => {
                              setSourceData({
                                ...sourceData,
                                meta_json: {
                                  ...sourceData.meta_json,
                                  reference_source_link: originalSourceMeta?.reference_source_link,
                                },
                              });
                            }}
                            type="button"
                            className="h-7 w-7 absolute right-0 top-7 mr-2"
                          >
                            <X className="h-4 w-4 min-w-[16px]" strokeWidth={1.75} />
                          </Button>
                        )}
                      {originalSourceMeta?.reference_source_link !==
                        sourceData?.meta_json?.reference_source_link &&
                        !isValidCitationLink && (
                          <p className="text-xs text-destructive ml-1 mt-1">Invalid link.</p>
                        )}
                    </div>
                    <div className="flex justify-end mt-2">
                      <Button size="sm" disabled={!canUpdate} onClick={updateProperties}>
                        {updating && <Loader2 strokeWidth={1.75} className="mr-2 h-4 w-4 animate-spin" />}
                        {updating ? 'Saving' : 'Save'}
                      </Button>
                    </div>
                  </AccordionContent>
                </AccordionItem>
                {isUrlSource && (
                  <AccordionItem disabled={!isSourceSucceeded} value="auto-retrain">
                    <AccordionTrigger
                      onClick={() => {
                        if (accordionActiveTabs.includes('auto-retrain')) {
                          setAccordionActiveTabs(
                            [...accordionActiveTabs].filter((v) => v !== 'auto-retrain'),
                          );
                        } else {
                          setAccordionActiveTabs([...accordionActiveTabs, 'auto-retrain']);
                        }
                      }}
                      className="py-2 px-6 bg-muted/30 hover:no-underline uppercase text-muted-foreground text-xs"
                    >
                      URL Auto Re-train
                    </AccordionTrigger>
                    <AccordionContent className="border-t px-6 py-4 flex flex-col gap-2">
                      <p className="text-muted-foreground text-xs">
                        Set the auto re-train frequency for the selected source. This will automatically
                        re-train source according to the frequency you set.
                      </p>
                      <Select
                        value={sourceData?.meta_json?.retrain_frequency?.toString() || '0'}
                        onValueChange={(value) => {
                          setSourceData({
                            ...sourceData,
                            meta_json: {
                              ...sourceData?.meta_json,
                              retrain_frequency: parseInt(value, 10) as ReTrainFrequency,
                            },
                          });
                        }}
                      >
                        <SelectTrigger className="mt-2">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="7">Weekly</SelectItem>
                          <SelectItem value="14">Biweekly</SelectItem>
                          <SelectItem value="30">Monthly</SelectItem>
                          <SelectItem value="0">No Re-train</SelectItem>
                        </SelectContent>
                      </Select>
                      <div className="flex justify-end mt-2">
                        <Button
                          size="sm"
                          disabled={
                            sourceData?.meta_json?.retrain_frequency ===
                              originalSourceMeta?.retrain_frequency || settingAutoRetrain
                          }
                          onClick={setAutoRetrain}
                        >
                          {settingAutoRetrain && (
                            <Loader2 strokeWidth={1.75} className="w-4 h-4 mr-2 animate-spin" />
                          )}
                          {settingAutoRetrain ? 'Saving' : 'Save'}
                        </Button>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                )}
              </Accordion>
            </div>
          )}
        </div>
      </div>
      <SubscriptionLimitsExceededDialog
        type="source-chunks"
        show={showLimitExceededDialog}
        close={() => setShowLimitExceededDialog(false)}
      />
      {openTableData && (
        <SourceTableDataDialog
          source={source}
          close={() => {
            setOpenTableData(false);
          }}
        />
      )}
      {openQAData && source && (
        <EditQASourceDialog
          source={source}
          close={() => {
            setOpenQAData(false);
          }}
        />
      )}
      {openSourceChunks &&
        source &&
        createPortal(
          <SourceChunksDialog
            source={source}
            hide={() => {
              setOpenSourceChunks(false);
            }}
          />,
          document.body,
        )}
    </>
  );
};

export default SourceDetailsDrawer;
