/* eslint-disable no-useless-escape */
import { useMutation } from '@tanstack/react-query';
import sourceService from 'api/source';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Textarea } from 'components/ui/textarea';
import useDataSources from 'hooks/useDataSources';
import { CirclePlus, Loader2 } from 'lucide-react';
import { Chatbot } from 'models/api/response.types';
import { useAlerts } from 'providers/AlertProvider';
import React, { useMemo, useState } from 'react';
import { alerts } from 'utils/alert';

const YouTubeUploader: React.FC<{ chatbot: Chatbot; close: () => void }> = ({ chatbot, close }) => {
  const { addAlert } = useAlerts();
  const { refetchSources } = useDataSources();
  const [loading, setLoading] = useState<boolean>(false);
  const [urls, setUrls] = useState<string>('');

  // splitting string to get list fo urls
  const urlList = useMemo(() => {
    return urls.split('\n');
  }, [urls]);

  const isValidUrls = useMemo(() => {
    const regex =
      /(https?:\/\/)?(www\.)?(youtube|youtu|youtube-nocookie)\.(com|be)\/(watch\?v=|embed\/|v\/|.+\?v=)?([^&=%\?]{11})/;
    return urls.split('\n').every((url) => regex.test(url));
  }, [urlList]);

  const createYouTubeVideosSourceMutation = useMutation({
    mutationFn: (payload: { uuid: string; urls: string[] }) =>
      sourceService.createBatchUrlsDataSources(payload.uuid, payload.urls),
    onSuccess: () => {
      refetchSources();
      close();
    },
    onError: (err: any) => {
      if (err?.response?.status === 409) {
        addAlert({
          severity: 'error',
          message: alerts.SOMETHING_WRONG,
        });
      }
      setLoading(false);
    },
  });

  const handleCreate = () => {
    setLoading(true);
    createYouTubeVideosSourceMutation.mutate({
      uuid: chatbot.uuid,
      urls: urlList,
    });
  };

  return (
    <div className="w-full max-h-full overflow-auto rounded-md bg-background p-6 text-left align-middle shadow-sm border">
      <h2 className="text-lg font-medium leading-6 text-gray-900">Add YouTube Videos</h2>
      <p className="font-normal text-muted-foreground text-sm mt-2">
        Add content by providing valid YouTube video URLs. We will extract the video transcripts to enhance
        the chatbot&apos;s training data.
      </p>
      <p className="font-normal text-warning text-sm mt-2">
        You must enable citations for your chatbot in order for uploaded videos to be embedded in chat
        messages.
      </p>
      <div className="mt-6">
        <Textarea
          disabled={loading}
          id="urls"
          placeholder="https://www.youtube.com/watch?v=sfMC0sxZVIY"
          className="min-h-[150px] lg:min-h-[300px]"
          value={urls}
          onChange={(e) => {
            const { value } = e.target;
            setUrls(value);
          }}
        />
        <p className="text-xs text-muted-foreground ml-1 mt-1">Enter each URL on a separate line</p>
        {!isValidUrls && urls.length > 0 && (
          <p className="text-xs text-destructive ml-1 mt-1">Invalid URL(s).</p>
        )}
      </div>
      <div className="flex justify-end mt-6 gap-4">
        <Button
          onClick={() => {
            handleCreate();
          }}
          className="self-start"
          disabled={urls.length === 0 || loading || !isValidUrls}
        >
          {loading ? (
            <Loader2 strokeWidth={1.75} className="mr-2 h-4 w-4 animate-spin" />
          ) : (
            <CirclePlus strokeWidth={1.75} className="mr-2 h-4 w-4" />
          )}
          {loading ? 'Adding' : 'Add'}
        </Button>
      </div>
    </div>
  );
};

export default YouTubeUploader;
