import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { CirclePlus, Pencil, Search, Tags } from 'lucide-react';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import useSourceTags from 'hooks/useSourceTags';
import { useAlerts } from 'providers/AlertProvider';
import { AddTagToSources, DataSource, SourceTag } from 'models/api/response.types';
import { EventBus } from 'utils/eventBus';
import sourceTagService from 'api/tag';
import { useMutation } from '@tanstack/react-query';
import { alerts as alertText } from 'utils/alert';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import TagForm from './TagForm';
import Tag from './Tag';

const TagManager: React.FC<{ selectedSources: DataSource[] }> = ({ selectedSources }) => {
  const { alerts, addAlert } = useAlerts();
  const { sourceTags, updateTagsSourceIds, invalidateSourceTagQuery } = useSourceTags();
  const [show, setShow] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [tagToEdit, setTagToEdit] = useState<SourceTag | undefined>(undefined);
  // for adding sources to tags
  const [sourcesToAdd, setSourcesToAdd] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const filteredTags = useMemo(() => {
    return sourceTags
      ? sourceTags?.filter((tag) => tag.name.toLowerCase().includes(searchTerm.toLowerCase()))
      : [];
  }, [sourceTags, searchTerm]);

  useEffect(() => {
    const addMultiSource = (data: any) => {
      setSourcesToAdd(data);
      setShow(true);
    };

    // when user wants to edit tag from source drawer
    // EventBus.on('edit-source-tag', handler);

    // when user wants to add sources to multiple documents
    EventBus.on('add-multi-source-tag', addMultiSource);

    return () => {
      // EventBus.off('edit-source-tag', handler);
      EventBus.off('add-multi-source-tag', addMultiSource);
    };
  }, []);

  const clearAddSources = () => {
    setTimeout(() => {
      setSelectedTags([]);
      setSourcesToAdd([]);
    }, 300);
  };

  const addTagsToSourcesMutation = useMutation({
    mutationFn: (payload: AddTagToSources) => sourceTagService.addTagsToSources(payload),
    onMutate: (payload) => {
      updateTagsSourceIds(payload);
      setShow(false);
      clearAddSources();
    },
    onError: () => {
      addAlert({
        severity: 'error',
        message: alertText.SOMETHING_WRONG,
      });
      invalidateSourceTagQuery();
    },
  });

  const addTagsToSources = () => {
    const obj: AddTagToSources = {};
    selectedTags.forEach((tagId) => {
      // first need to get existing doc ids
      const source_ids = sourceTags?.find((tag) => tag.uuid === tagId)?.data_source_uuids;
      // combinign new docIds with existing
      obj[tagId] = [...sourcesToAdd, ...(source_ids || [])];
    });
    addTagsToSourcesMutation.mutate(obj);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="ml-auto bg-background">
            <Tags strokeWidth={1.75} className="h-4 w-4 mr-2" />
            Tags
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="mr-10">
          <DropdownMenuItem
            onClick={() => {
              setShow(true);
            }}
          >
            <Pencil strokeWidth={1.75} className="mr-2 h-4 w-4" />
            Manage all tags
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              if (selectedSources.length) {
                setSourcesToAdd(selectedSources.map((src) => src.uuid));
                setShow(true);
              } else {
                addAlert({
                  severity: 'warning',
                  message: alertText.SELECT_SOURCES_TAG,
                });
              }
            }}
          >
            <img src="/img/add-tag.png" className="mr-2 h-4 w-4" alt="Add Tags" />
            Add tags to sources
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[50] border-md"
          onClose={() => {
            if (alerts.length === 0) {
              setShow(false);
            }
            if (sourcesToAdd.length) {
              clearAddSources();
            }
          }}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-2xl transform overflow-visible rounded-md bg-background p-6 text-left align-middle shadow-xl transition-all">
                  <DialogTitle as="h1" className="text-lg font-medium leading-6 text-gray-900">
                    {sourcesToAdd.length ? 'Add Tags' : 'Manage Tags'}
                  </DialogTitle>
                  {!!sourceTags?.length && (
                    <div className="relative mb-6 mt-8">
                      <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                      <Input
                        type="text"
                        placeholder="Search tags..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="pl-8"
                      />
                    </div>
                  )}
                  <div className="flex items-center flex-wrap mt-6 gap-2 max-h-[240px] overflow-auto p-1">
                    {filteredTags.length ? (
                      <>
                        {filteredTags.map((tag) => {
                          const isSelectable = !!sourcesToAdd.length;
                          const selected = selectedTags.includes(tag.uuid);
                          return (
                            <Tag
                              canSelect={isSelectable}
                              selected={selected}
                              selectTag={() => {
                                if (selected) {
                                  setSelectedTags([...selectedTags.filter((tagId) => tagId !== tag.uuid)]);
                                } else {
                                  setSelectedTags([...selectedTags, tag.uuid]);
                                }
                              }}
                              size="md"
                              key={tag.uuid}
                              tag={tag}
                              showDeleteButton={!isSelectable}
                              showDocCounter
                              showEditButton={!isSelectable}
                              editingHiglight={tagToEdit?.uuid === tag.uuid}
                              onEdit={() => setTagToEdit(tag)}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <p className="text-sm text-muted-foreground italic">
                          {searchTerm
                            ? 'No tags found within your search criteria.'
                            : 'Start with adding your first tag.'}
                        </p>
                      </>
                    )}
                  </div>
                  {/* can't be visible in add sources to tags flow */}
                  {!sourcesToAdd.length ? (
                    <TagForm editTag={tagToEdit} stopEditing={() => setTagToEdit(undefined)} />
                  ) : (
                    <div className="mt-6 flex justify-end">
                      <Button disabled={!selectedTags.length} onClick={addTagsToSources}>
                        <CirclePlus className="h-4 w-4 mr-2" strokeWidth={1.75} />
                        Assign tag(s) to selection
                      </Button>
                    </div>
                  )}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default TagManager;
