import axios from 'axios';
import {
  AddTagToSources,
  SourceTagCreateBody,
  SourceTagList,
  SourceTagUpdateBody,
} from 'models/api/response.types';

const getSourceTags = (uuid: string) =>
  axios.get<SourceTagList>(`/api/chatbot/${uuid}/source-tags`).then((res) => res.data);

const createSourceTag = (body: SourceTagCreateBody) =>
  axios.post(`/api/source-tag/create`, body).then((res) => res.data);

const updateSourceTag = (uuid: string, body: SourceTagUpdateBody) =>
  axios.post(`/api/source-tag/${uuid}/update`, body).then((res) => res.data);

const deleteSourceTag = (uuid: string) =>
  axios.delete(`/api/source-tag/${uuid}/delete`).then((res) => res.data);

const addTagsToSources = (body: AddTagToSources) =>
  axios.post(`/api/source-tag/update-list`, body).then((res) => res.data);

const sourceTagService = {
  getSourceTags,
  createSourceTag,
  updateSourceTag,
  deleteSourceTag,
  addTagsToSources,
};

export default sourceTagService;
