/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Chatbot, DataSource } from 'models/api/response.types';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';
import SourceStatus, { sourceErrorStatuses } from 'components/Chatbot/Sources.tsx/SourceTable/SourceStatus';
import { formatBytes, numberWithDots } from 'utils/formatting';
import { Skeleton } from 'components/ui/skeleton';
import { Button } from 'components/ui/button';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
  X,
  Trash2,
  History,
  RefreshCw,
  RefreshCcwDot,
  PlusCircle,
  Loader2,
  Download,
  MoreVertical,
  BookmarkCheck,
  BookmarkX,
  Search,
} from 'lucide-react';
import { cn } from 'utils/cn';
import { sortDataTableDocuments } from 'utils/dataTableSort';
import { format } from 'date-fns';
import sourceService from 'api/source';
import { useDispatch, useSelector } from 'react-redux';
import {
  SourceColumn,
  getSelectedChatbot,
  getSourceTableColumnsHidden,
  setSourceTableColumnsHidden,
} from 'store/reducers/ui';
import { Checkbox } from 'components/ui/checkbox';
import ConfirmSourceDeletionDialog from 'components/Dialogs/Sources/ConfirmSourceDeletionDialog';
import LoadingOverlay from 'components/helpers/LoadingOverlay';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import DataTableColumnHeader from 'components/helpers/Table/DataTableColumnHeader';
import DataTableViewColumnFilter from 'components/helpers/Table/DataTableViewColumnFilter';
import useDataSources from 'hooks/useDataSources';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import AutoRetrainSourcesDialog from 'components/Dialogs/Sources/AutoRetrainSourcesDialog';
import { downloadDataSource } from 'utils/dataSources';
import { useAlerts } from 'providers/AlertProvider';
import { alerts } from 'utils/alert';
import { DataRow, SortSource, sourceTypes } from 'utils/sources';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import useSourceTags from 'hooks/useSourceTags';
import FilterButton, { Filters } from '../../../helpers/FilterButton';
import SourceType from './SourceType';
import SourceFileName from './SourceFileName';
import AddNewSource from '../AddNewSource/AddNewSource';
import SourceRetrainStatus from './SourceRetrainStatus';
import GoogleDriveUploader from '../AddNewSource/GoogleDrive/GoogleDriveUploader';
import SourceDetailsDrawer from './SourceDetailsDrawer';
import TagManager from '../TagManager/TagManager';
import SourceTagList from './SourceTagList';

const SourceTable: React.FC<{
  isLoading: boolean;
  data: DataSource[];
  usedData: {
    tokens: number;
    file_size: number;
  };
}> = ({ isLoading, data, usedData }) => {
  const { addAlert } = useAlerts();
  const { chatbotSubscription, canUseAutoRetrain } = useSubscriptionInfo();
  const { refetchSources } = useDataSources();
  const { sourceTags } = useSourceTags();
  const isInitialMount = useRef<boolean>(true);
  const dispatch = useDispatch();
  const chatbot = useSelector(getSelectedChatbot) as Chatbot;
  const sourceTableColumsHidden = useSelector(getSourceTableColumnsHidden);
  const [filterValue, setFilterValue] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('asc');
  const [sortColumn, setSortColumn] = useState<SortSource>('date_added');
  const [sourcesToDelete, setSourcesToDelete] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(50);
  const [selectedSources, setSelectedSources] = useState<DataSource[]>([]);
  const [sourceDataToShow, setSourceDataToShow] = useState<DataRow | undefined>(undefined);
  const [addSources, setAddSources] = useState<boolean>(false);
  const [downloadingFiles, setDownloadingFiles] = useState<boolean>(false);
  const [autoRetrainSourcesToSet, setAutoRetrainSourcesToSet] = useState<DataSource[]>([]);
  const [validFilters, setValidFilters] = useState<Filters>([]);
  const [selectedFilters, setSelectedFilters] = useState<Filters>([]);

  // on chatbot change reset everything
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setSelectedSources([]);
      setFilterValue('');
      setCurrentPage(1);
      setSelectedFilters([]);
    }
  }, [chatbot]);

  // original source data constructing by adding new properties
  // that we can show in the table
  const rowData: DataRow[] = useMemo(() => {
    return data.map((src) => {
      let canRetry = false;
      if (chatbotSubscription) {
        const { storage_max, chatbot_tokens_max } = chatbotSubscription.usage;
        const { status, file_size, tokens } = src;
        if (status === 'error:storage' && usedData.file_size + file_size <= storage_max) {
          canRetry = true;
        }
        if (status === 'error:token' && usedData.tokens + tokens <= chatbot_tokens_max) {
          canRetry = true;
        }
        if (status !== 'error:token' && status !== 'error:storage') {
          canRetry = true;
        }
        return {
          ...src,
          canRetry,
        };
      }
      return {
        ...src,
        canRetry,
      };
    });
  }, [data, chatbotSubscription]);

  // if data was updated and user has selected source to see properties
  // need to update it (in cases if that source was affected by updates)
  useEffect(() => {
    if (sourceDataToShow) {
      const sourceToUse = rowData?.find((src) => src.uuid === sourceDataToShow.uuid);
      setSourceDataToShow(sourceToUse);
    }
  }, [rowData]);

  // filtering data by search input
  // based on this we can also construct filter input
  const sortedAndSearchedSourceData = useMemo(() => {
    let searchedOutput = rowData.filter((source) => {
      const { type, meta_json, file_name, title } = source;
      const meta = JSON.parse(meta_json);

      if (filterValue) {
        const value = filterValue.toLocaleLowerCase();
        if (title.toLocaleLowerCase().includes(value)) {
          return true;
        }
        if (['url', 'video'].includes(type) && meta.url.toLocaleLowerCase().includes(value)) {
          return true;
        }
        if (
          type === 'qa' &&
          (meta.question.toLocaleLowerCase().includes(value) ||
            meta.answer.toLocaleLowerCase().includes(value))
        ) {
          return true;
        }
        if (
          (type === 'upload' || type === 'google-drive' || type === 'table') &&
          file_name.toLocaleLowerCase().includes(value)
        ) {
          return true;
        }
        return false;
      }
      return true;
    });

    // if searched sources exist, need to sort them by selected column and order
    if (sortColumn && searchedOutput.length > 0) {
      searchedOutput = sortDataTableDocuments(searchedOutput, sortColumn, sortOrder) as DataRow[];
    }

    // adding filters based on result sources
    // get valid source types based on searched sources
    const typeFilters = new Set();
    searchedOutput.forEach((src) => typeFilters.add(src.type));
    //  get tags based on searched sources
    const tagFilters =
      sourceTags?.filter((tag) => searchedOutput.some((doc) => tag.data_source_uuids.includes(doc.uuid))) ||
      [];
    setValidFilters([...(Array.from(typeFilters) as string[]), ...tagFilters]);

    return searchedOutput;
  }, [rowData, filterValue, sortColumn, sortOrder, sourceTags]);

  // filter sources based on selected filters, type or tags
  const filteredSourceData = useMemo(() => {
    if (selectedFilters.length) {
      return sortedAndSearchedSourceData.filter((src) => {
        return selectedFilters.some((filter: any) => {
          // filter has data_source_uuids means tag
          // otherwise type
          if (filter?.data_source_uuids) {
            return filter.data_source_uuids.includes(src.uuid);
          }
          return src.type === filter;
        });
      });
    }
    return sortedAndSearchedSourceData;
  }, [sortedAndSearchedSourceData, selectedFilters]);

  const totalPages = filteredSourceData ? Math.ceil(filteredSourceData.length / itemsPerPage) : 1;

  const indexes = useMemo(() => {
    if (filteredSourceData) {
      const startIndex = (currentPage - 1) * itemsPerPage;
      return {
        startIndex,
        endIndex: startIndex + itemsPerPage,
      };
    }
    return {
      startIndex: 1,
      endIndex: 1,
    };
  }, [filteredSourceData, currentPage, itemsPerPage]);

  const dataToUse = useMemo(() => {
    return filteredSourceData.slice(indexes.startIndex, indexes.endIndex);
  }, [indexes, filteredSourceData]);

  const retrainURLs = (uuids: string[]) => {
    sourceService.urlsReScraping(uuids).then(() => {
      refetchSources();
    });
  };

  const retryDataSources = (uuids: string[]) => {
    sourceService.retryDataSources(uuids).then(() => {
      refetchSources();
    });
  };

  useEffect(() => {
    if (totalPages < currentPage && totalPages > 0) {
      setCurrentPage(totalPages);
    }
  }, [totalPages]);

  const filterColumns = useMemo(() => {
    let columns: SourceColumn[] = ['Type', 'Status', 'Tags', 'Size', 'Tokens', 'Last Trained On'];
    if (canUseAutoRetrain) {
      const newArray = [...columns];
      newArray.splice(newArray.length - 1, 0, 'Re-train');
      columns = newArray;
    }
    return columns.map((title) => {
      return {
        title,
        checked: !sourceTableColumsHidden.includes(title),
      };
    });
  }, [sourceTableColumsHidden, canUseAutoRetrain]);

  const updateSources = (updates: { [key: string]: any }) => {
    const sourcesIds = selectedSources.map((src) => src.uuid);
    sourceService
      .updateBatchDataSources(chatbot.uuid, {
        uuids: sourcesIds,
        updates,
      })
      .then(() => {
        refetchSources();
      })
      .catch(() => {
        addAlert({
          severity: 'error',
          message: alerts.SOMETHING_WRONG,
        });
      });
  };

  const downloadSelectedSources = () => {
    // filter urls/videos as not downloadable files
    const sourcesToUse = selectedSources.filter((src) => !['url', 'video'].includes(src.type));
    const isMultiFiles = sourcesToUse.some((src) => src.type !== 'qa') && sourcesToUse.length > 1;
    // if multi files  title should contian zip extension at the end
    let fileTitle = `${chatbot?.name}-sources.zip`;

    if (!isMultiFiles) {
      // all q&a downloads as single txt file
      if (selectedSources[0].type === 'qa') {
        fileTitle = `${chatbot?.name}-qa-data-sources.txt`;
      } else {
        fileTitle = `${selectedSources[0].file_name}`;
      }
    }
    setDownloadingFiles(true);
    downloadDataSource(
      { file_name: fileTitle, uuids: sourcesToUse.map((src) => src.uuid) },
      (success: boolean) => {
        setDownloadingFiles(false);
        if (success) {
          addAlert({
            severity: 'success',
            message: alerts.FILE_DOWNLOAD_SUCCESS(fileTitle),
          });
          setSelectedSources([]);
        } else {
          addAlert({
            severity: 'error',
            message: alerts.FILE_DONWLOAD_ERROR(fileTitle),
          });
        }
      },
    );
  };

  const handleShiftItemClick = (source: DataRow) => {
    let lastIndex;
    let firstIndex;
    const lastSelectedIndex = dataToUse.findIndex((src: DataRow) => src.uuid === source.uuid);
    let firstSelectedIndex = dataToUse.findIndex((src: DataRow) => src.uuid === selectedSources[0]?.uuid);
    if (firstSelectedIndex > lastSelectedIndex) {
      firstSelectedIndex = dataToUse?.findIndex(
        (src: DataRow) => src.uuid === selectedSources[selectedSources.length - 1]?.uuid,
      );
      firstIndex = lastSelectedIndex;
      lastIndex = firstSelectedIndex;
    } else {
      firstIndex = firstSelectedIndex;
      lastIndex = lastSelectedIndex;
    }
    setSelectedSources(dataToUse.slice(firstIndex, lastIndex + 1));
  };

  const generatHeader = (title: SourceColumn, canHide: boolean, key?: SortSource, canSort?: boolean) => {
    return (
      <DataTableColumnHeader
        title={title}
        sortOrder={{
          key: key || '',
          column: sortColumn,
          order: sortOrder,
        }}
        canSort={canSort}
        setColumn={(k) => setSortColumn(k as SortSource)}
        setOrder={setSortOrder}
        hideColumn={() => {
          if (canHide) {
            dispatch(setSourceTableColumnsHidden([...sourceTableColumsHidden, title]));
          }
        }}
      />
    );
  };

  if (isLoading) {
    return (
      <div className="h-full flex items-center justify-center">
        <LoadingOverlay />
      </div>
    );
  }

  if (data.length === 0 || addSources) {
    return (
      <AddNewSource usedData={usedData} dataExists={data.length > 0} close={() => setAddSources(false)} />
    );
  }

  return (
    <div className="h-full flex flex-col gap-1 overflow-hidden py-4 px-4 sm:px-6 relative">
      <div className="flex p-[1px] pb-3 gap-3 items-center flex-row overflow-x-auto">
        <div className="flex items-center gap-2 h-9 w-fit min-w-[300px] rounded-md border transition-all  ease-in-out border-input bg-background px-3 pr-2 py-1 text-sm shadow-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring">
          <Search className="h-4 w-4 min-w-[16px]" strokeWidth={1.75} />
          <input
            id="search-sources"
            type="text"
            maxLength={50}
            autoComplete="off"
            placeholder="Search"
            value={filterValue}
            onChange={(e) => {
              setCurrentPage(1);
              setSelectedSources([]);
              setFilterValue(e.target.value);
              setSelectedFilters([]);
            }}
            className="w-full h-full placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50"
          />
          {filterValue.length > 0 && (
            <Button
              size="sm"
              variant="ghost"
              onClick={() => {
                setFilterValue('');
              }}
              type="button"
              className="h-7 w-7"
            >
              <X className="h-4 w-4 min-w-[16px]" strokeWidth={1.75} />
            </Button>
          )}
        </div>
        {!!validFilters.length && (
          <FilterButton
            title="Filter"
            options={sourceTypes}
            validFilters={validFilters}
            selectedFilters={selectedFilters}
            setSelectedFilters={(newFilter: Filters) => {
              setCurrentPage(1);
              setSelectedFilters(newFilter);
            }}
          />
        )}
        <div className="flex items-center flex-row ml-auto gap-3">
          {!!selectedSources.length && (
            <>
              {selectedSources.some((source) => source.type === 'url') && (
                <TooltipProvider>
                  <Tooltip className="h-full">
                    <TooltipTrigger asChild>
                      <Button
                        className="ml-auto bg-background whitespace-nowrap"
                        size="icon"
                        variant="outline"
                        onClick={() => {
                          const urlSources = selectedSources
                            .filter((src) => src.type === 'url')
                            .map((src) => src.uuid);
                          retrainURLs(urlSources);
                          setSelectedSources([]);
                        }}
                      >
                        <RefreshCw strokeWidth={1.75} className="h-4 w-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent className="font-normal">Re-train sources</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
              {selectedSources?.some((source) => !['video', 'url'].includes(source.type)) && (
                <TooltipProvider>
                  <Tooltip className="h-full">
                    <TooltipTrigger asChild>
                      <Button
                        className="ml-auto bg-background whitespace-nowrap"
                        size="icon"
                        variant="outline"
                        disabled={downloadingFiles}
                        onClick={downloadSelectedSources}
                      >
                        {downloadingFiles ? (
                          <Loader2 strokeWidth={1.75} className="h-4 w-4 animate-spin" />
                        ) : (
                          <Download strokeWidth={1.75} className="h-4 w-4" />
                        )}
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent className="font-normal">Download sources</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
              <TooltipProvider>
                <Tooltip className="h-full">
                  <TooltipTrigger asChild>
                    <Button
                      className="ml-auto bg-background whitespace-nowrap hover:text-destructive hover:bg-destructive/10 hover:border-destructive"
                      size="icon"
                      variant="outline"
                      onClick={() => {
                        setSourcesToDelete(selectedSources.map((src) => src.uuid));
                      }}
                    >
                      <Trash2 strokeWidth={1.75} className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="font-normal">Delete sources</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </>
          )}
          {rowData.some((src) => src.canRetry && sourceErrorStatuses.includes(src.status)) && (
            <TooltipProvider>
              <Tooltip className="h-full">
                <TooltipTrigger asChild>
                  <Button
                    size="icon"
                    variant="outline"
                    className="ml-auto bg-background"
                    onClick={() => {
                      retryDataSources(
                        rowData
                          .filter((src) => src.canRetry && sourceErrorStatuses.includes(src.status))
                          .map((src) => src.uuid),
                      );
                    }}
                  >
                    <History strokeWidth={1.75} className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent className="font-normal">Retry failed sources</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
          {selectedSources.length > 0 && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" size="icon" className="ml-auto bg-background">
                  <MoreVertical strokeWidth={1.75} className="h-4 w-4" />
                  <span className="sr-only">Open menu</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="mr-10">
                {selectedSources.some((src) => src.type === 'url') && (
                  <DropdownMenuItem
                    onClick={() => {
                      const urlSources = selectedSources.filter((src) => src.type === 'url');
                      setAutoRetrainSourcesToSet(urlSources);
                    }}
                  >
                    <RefreshCcwDot strokeWidth={1.75} className="mr-2 h-4 w-4" />
                    Auto Re-train
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem
                  onClick={() => {
                    updateSources({ enable_citing: true });
                  }}
                >
                  <BookmarkCheck strokeWidth={1.75} className="mr-2 h-4 w-4" />
                  Enable Citations/Snippets
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    updateSources({ enable_citing: false });
                  }}
                >
                  <BookmarkX strokeWidth={1.75} className="mr-2 h-4 w-4" />
                  Disable Citations/Snippets
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
          <TagManager selectedSources={selectedSources} />
          <DataTableViewColumnFilter
            columns={filterColumns}
            hideColumn={(column: SourceColumn) => {
              dispatch(setSourceTableColumnsHidden([...sourceTableColumsHidden, column]));
            }}
            enableColumn={(column: SourceColumn) => {
              const newColumns = [...sourceTableColumsHidden.filter((c) => c !== column)];
              dispatch(setSourceTableColumnsHidden(newColumns));
            }}
          />
          <Button
            onClick={() => {
              setAddSources(true);
            }}
          >
            <PlusCircle strokeWidth={1.75} className="w-4 h-4 mr-2" />
            Add sources
          </Button>
        </div>
      </div>
      <div className="flex-1 overflow-hidden relative flex">
        <Table
          containerClasses={cn(
            'border bg-background max-h-full rounded-md',
            sourceDataToShow && 'rounded-r-none border-r-0',
          )}
          className="table-fixed"
        >
          <TableHeader>
            <TableRow>
              <TableHead className="w-[40px] py-2 pl-3 pr-0">
                <Checkbox
                  className="mt-2"
                  disabled={dataToUse.length === 0}
                  checked={selectedSources.length === dataToUse.length && dataToUse.length > 0}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setSelectedSources(dataToUse);
                    } else {
                      setSelectedSources([]);
                    }
                  }}
                  aria-label="Select rows"
                />
              </TableHead>
              <TableHead className="group w-[320px] px-2">
                {generatHeader('Resource', false, 'file_name', true)}
              </TableHead>
              {!sourceTableColumsHidden.includes('Type') && (
                <TableHead className="group w-[100px] px-2">
                  {generatHeader('Type', true, 'type', true)}
                </TableHead>
              )}
              {!sourceTableColumsHidden.includes('Status') && (
                <TableHead className={cn('group px-2 w-[130px]')}>
                  {generatHeader('Status', true, 'status', true)}
                </TableHead>
              )}
              {!sourceTableColumsHidden.includes('Tags') && (
                <TableHead className={cn('group px-2 w-[250px]')}>
                  {generatHeader('Tags', true, undefined, false)}
                </TableHead>
              )}
              {!sourceTableColumsHidden.includes('Size') && (
                <TableHead className="group w-[150px] px-2">
                  {generatHeader('Size', true, 'size', true)}
                </TableHead>
              )}
              {!sourceTableColumsHidden.includes('Tokens') && (
                <TableHead className="group w-[150px] px-2">
                  {generatHeader('Tokens', true, 'tokens', true)}
                </TableHead>
              )}
              {canUseAutoRetrain && !sourceTableColumsHidden.includes('Re-train') && (
                <TableHead className="group w-[100px] px-2">{generatHeader('Re-train', true)}</TableHead>
              )}
              {!sourceTableColumsHidden.includes('Last Trained On') && (
                <TableHead className="group w-[170px] px-2">
                  {generatHeader('Last Trained On', true, 'last_trained_on', true)}
                </TableHead>
              )}
            </TableRow>
          </TableHeader>
          <TableBody>
            {dataToUse?.length > 0 ? (
              dataToUse.map((source) => {
                const isSelectedByCheckbox = selectedSources.some((src) => src.uuid === source.uuid);
                const isSelectedToShow = sourceDataToShow?.uuid === source.uuid;

                return (
                  <TableRow
                    className={cn('cursor-pointer group select-none', isSelectedToShow && 'bg-muted')}
                    data-state={isSelectedByCheckbox && 'selected'}
                    key={source.uuid}
                    onClick={(e) => {
                      if (e.shiftKey || e.ctrlKey || e.metaKey) {
                        if (e.shiftKey) {
                          if (selectedSources.length === 0) {
                            setSelectedSources([source]);
                          } else {
                            handleShiftItemClick(source);
                          }
                        } else {
                          setSelectedSources([...selectedSources, source]);
                        }
                      } else {
                        setSelectedSources([source]);
                        setSourceDataToShow(source);
                      }
                    }}
                  >
                    <TableCell className="py-2 pl-3 pr-0">
                      <Checkbox
                        className={cn('mt-2', isSelectedByCheckbox ? 'border-none border-0' : '')}
                        checked={isSelectedByCheckbox}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (e.shiftKey) {
                            handleShiftItemClick(source);
                          } else if (!isSelectedByCheckbox) {
                            setSelectedSources([...selectedSources, source]);
                          } else {
                            const newSelection = selectedSources.filter((src) => src.uuid !== source.uuid);
                            setSelectedSources(newSelection);
                          }
                        }}
                        aria-label="Select row"
                      />
                    </TableCell>
                    <TableCell className="p-2">
                      <SourceFileName source={source} />
                    </TableCell>
                    {!sourceTableColumsHidden.includes('Type') && (
                      <TableCell className="p-2">
                        <SourceType source={source} />
                      </TableCell>
                    )}
                    {!sourceTableColumsHidden.includes('Status') && (
                      <TableCell className="p-2">
                        <SourceStatus
                          source={source}
                          retryDataSource={(sourceId: string) => {
                            retryDataSources([sourceId]);
                          }}
                        />
                      </TableCell>
                    )}
                    {!sourceTableColumsHidden.includes('Tags') && (
                      <TableCell className="p-2">
                        <SourceTagList source={source} />
                      </TableCell>
                    )}
                    {!sourceTableColumsHidden.includes('Size') && (
                      <TableCell className="p-2">
                        {source.file_size > -1 ? (
                          <p
                            className={cn(
                              'text-sm whitespace-nowrap',
                              source.status === 'error:storage' && !source.canRetry ? 'text-destructive' : '',
                            )}
                          >
                            {formatBytes(source.file_size)}
                          </p>
                        ) : (
                          <Skeleton className="w-[50px] h-[22px]" />
                        )}
                      </TableCell>
                    )}
                    {!sourceTableColumsHidden.includes('Tokens') && (
                      <TableCell className="p-2">
                        {source.tokens > -1 ? (
                          <p
                            className={cn(
                              'text-sm whitespace-nowrap',
                              source.status === 'error:token' && !source.canRetry ? 'text-destructive' : '',
                            )}
                          >
                            {numberWithDots(source.tokens)}
                          </p>
                        ) : (
                          <Skeleton className="w-[50px] h-[22px]" />
                        )}
                      </TableCell>
                    )}
                    {canUseAutoRetrain && !sourceTableColumsHidden.includes('Re-train') && (
                      <TableCell className="p-2">
                        <SourceRetrainStatus source={source} />
                      </TableCell>
                    )}
                    {!sourceTableColumsHidden.includes('Last Trained On') && (
                      <TableCell className="p-2 whitespace-nowrap">
                        {format(new Date(source.modified_at), 'yyyy-MM-dd HH:mm')}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={9 - sourceTableColumsHidden.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {sourceDataToShow && (
          <SourceDetailsDrawer
            source={sourceDataToShow}
            close={() => setSourceDataToShow(undefined)}
            deleteSource={() => {
              setSourcesToDelete([sourceDataToShow?.uuid as string]);
            }}
            retrainUrl={(sourceId: string) => {
              retrainURLs([sourceId]);
            }}
            retryDataSource={(sourceId: string) => {
              retryDataSources([sourceId]);
            }}
          />
        )}
      </div>
      <div className="flex items-center justify-between px-2 overflow-x-auto pb-1">
        <div className="flex-1 text-sm text-muted-foreground whitespace-nowrap mr-2">
          {selectedSources.length} of {dataToUse.length} row(s) selected.
        </div>
        <div className="flex items-center space-x-6 lg:space-x-8 mt-2">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-medium whitespace-nowrap">Rows per page</p>
            <Select
              value={itemsPerPage.toString()}
              onValueChange={(value) => {
                setCurrentPage(1);
                setItemsPerPage(Number(value));
              }}
            >
              <SelectTrigger className="h-8 w-[70px] bg-background">
                <SelectValue placeholder={itemsPerPage.toString()} />
              </SelectTrigger>
              <SelectContent side="top">
                {[30, 40, 50, 60, 70].map((pageSize) => (
                  <SelectItem key={pageSize} value={`${pageSize}`}>
                    {pageSize}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex whitespace-nowrap items-center justify-center text-sm font-medium">
            Page {currentPage} of {totalPages}
          </div>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              size="sm"
              className="hidden h-8 w-8 p-0 lg:flex bg-background"
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1}
            >
              <span className="sr-only">Go to first page</span>
              <ChevronsLeftIcon strokeWidth={1.75} className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              size="sm"
              className="h-8 w-8 p-0 bg-background"
              onClick={() => setCurrentPage((page) => page - 1)}
              disabled={currentPage === 1}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeftIcon strokeWidth={1.75} className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              size="sm"
              className="h-8 w-8 p-0 bg-background"
              onClick={() => setCurrentPage((page) => page + 1)}
              disabled={currentPage === totalPages}
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRightIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex bg-background"
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
            >
              <span className="sr-only">Go to last page</span>
              <ChevronsRightIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
      <ConfirmSourceDeletionDialog
        sourcesToDelete={sourcesToDelete}
        close={(success?: boolean) => {
          if (success) {
            setSelectedSources([]);
          }
          setSourcesToDelete([]);
        }}
      />
      <AutoRetrainSourcesDialog
        sources={autoRetrainSourcesToSet}
        hide={() => setAutoRetrainSourcesToSet([])}
      />
      <GoogleDriveUploader />
    </div>
  );
};

export default SourceTable;
