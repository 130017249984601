import { AccountProductToShow, SubscriptionInfo, UserInfo } from 'models/api/response.types';

export const defaultPriceToShow: {
  currency: string;
  interval: string;
  amount: number;
} = {
  currency: 'USD',
  interval: 'month',
  amount: 0,
};

export const generateFreePlan = (
  free_plan_keys: { [key: string]: boolean | number | string },
  userInfo: UserInfo,
): AccountProductToShow => {
  const isTrial = free_plan_keys?.limited_trial as boolean;
  const trialDays = (free_plan_keys?.trial_days as number) || 0;
  const userCreationTime = new Date(userInfo.created_at);
  // logic to generate trial end date
  const trialEndDate = new Date(userCreationTime);
  trialEndDate.setDate(userCreationTime.getDate() + trialDays);
  const isTrialCompleted = new Date() > trialEndDate;

  return {
    trial_date_quantity: trialDays,
    trial_completed: isTrialCompleted,
    period_end: trialEndDate.toISOString(),
    at_period_end: 'continue',
    lookup_key: 'free_plan',
    product_id: 'free_plan_unique_id',
    quantity: 1,
    status: isTrial ? 'trialing' : 'active',
    subscription_id: 'free_plan_unique_id',
    current_plan: {
      active: true,
      id: 'free_plan_unique_id',
      livemode: true,
      metadata: {},
      name: 'Free',
      type: 'service',
      unit_label: 'plan',
      features: [],
      prices: [
        {
          active: true,
          currency: 'usd',
          id: 'free_plan_unique_price_id',
          livemode: true,
          lookup_key: 'free_plan',
          unit_amount: 0,
          type: 'recurring',
          recurring: {
            interval: 'month',
          },
        },
      ],
    },
  };
};

export const currencySymbols: { [key: string]: string } = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  AUD: 'A$',
  CAD: 'C$',
  JPY: '¥',
  CNY: '¥',
  INR: '₹',
  BRL: 'R$',
  MXN: '$',
  SGD: 'S$',
  HKD: 'HK$',
  NZD: 'NZ$',
  SEK: 'kr',
  NOK: 'kr',
  DKK: 'kr',
  CHF: 'CHF',
  ZAR: 'R',
  RUB: '₽',
};

export const convertStripeAmount = (amount: number, currency: string, type: 'string' | 'number') => {
  const decimalPlaces: { [key: string]: number } = {
    USD: 2,
    EUR: 2,
    GBP: 2,
    AUD: 2,
    CAD: 2,
    JPY: 0,
    CNY: 2,
    INR: 2,
    BRL: 2,
    MXN: 2,
    SGD: 2,
    HKD: 2,
    NZD: 2,
    SEK: 2,
    NOK: 2,
    DKK: 2,
    CHF: 2,
    ZAR: 2,
    RUB: 2,
  };

  const symbol = currencySymbols[currency.toUpperCase()] || '';
  const decimals = decimalPlaces[currency.toUpperCase()] || 2;
  const amountInDecimal = amount / 10 ** decimals;

  if (type === 'string') {
    return `${symbol}${amountInDecimal.toFixed(decimals)}`;
  }
  return amountInDecimal.toFixed(decimals);
};

// widget plan check
export const canUseUserIdentity = (limits: { [key: string]: any }) => {
  return !!limits?.user_identity_verification;
};

export const canSeeChatbotProfilePicture = (limits: { [key: string]: any }) => {
  return !!limits?.chatbot_profile_picture;
};

export const canUseCustomBrandWatermark = (limits: { [key: string]: any }) => {
  return !!limits?.custom_watermark;
};

export const canRemoveAppBranding = (limits: { [key: string]: any }) => {
  return !!limits?.watermark_removal;
};

// only boolean keys can be purchased with no way of adding extra
export const checkIfAddonPurchased = (
  addonData: { [key: string]: string },
  planLimits?: SubscriptionInfo['usage_limits'],
) => {
  if (!planLimits) {
    return false;
  }
  const addonKeys = Object.keys(addonData);
  const booleanKeys = Object.keys(planLimits).filter(
    (key) => typeof planLimits[key as keyof SubscriptionInfo['usage_limits']] === 'boolean',
  );
  return addonKeys.some(
    (key) => booleanKeys.includes(key) && planLimits[key as keyof SubscriptionInfo['usage_limits']] === true,
  );
};
