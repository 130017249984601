import React, { useRef, useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { ChatBotMeta } from 'utils/bot';
import { Loader2, PanelBottom, Image as IconImage, UploadIcon } from 'lucide-react';
import { Label } from 'components/ui/label';
import { Input } from 'components/ui/input';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import UpgradeRequired from 'components/helpers/UpgradeRequired';
import { useWhitelabelData } from 'providers/WhiteLabelProvider';
import { Button } from 'components/ui/button';
import contentService from 'api/content';
import { Chatbot, FileResponseData } from 'models/api/response.types';
import { useSelector } from 'react-redux';
import useChatbots from 'hooks/useChatbots';
import { getSelectedChatbot } from 'store/reducers/ui';
import { alerts } from 'utils/alert';
import { useAlerts } from 'providers/AlertProvider';
import ImageCropperDialog from 'components/Dialogs/ImageCropperDialog';

interface WatermarkAppearanceProps extends React.HTMLProps<HTMLDivElement> {
  id: string;
  chatInterface: ChatBotMeta;
  setChatInterface: (meta: ChatBotMeta) => void;
}

const WatermarkAppearance = React.forwardRef<HTMLDivElement, WatermarkAppearanceProps>(
  ({ id, chatInterface, setChatInterface }, ref) => {
    const { addAlert } = useAlerts();
    const { updateChatbotByUUID } = useChatbots();
    const { mainWebsite } = useWhitelabelData();
    const chatbot = useSelector(getSelectedChatbot) as Chatbot;
    const { canUseCustomWatermark } = useSubscriptionInfo();
    const watermarkLogoRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [imageToUpload, setImageToUpload] = useState<File | undefined>(undefined);

    const updateWatermarkLogo = (file: File) => {
      setLoading(true);
      contentService.uploadChatbotFile(
        chatbot.uuid,
        file,
        'chatbot_watermark',
        (response?: FileResponseData) => {
          if (response) {
            const meta: ChatBotMeta = JSON.parse(chatbot.meta_json);
            updateChatbotByUUID(chatbot.uuid, {
              ...chatbot,
              meta_json: JSON.stringify({
                ...meta,
                custom_watermark: { ...meta?.custom_watermark, logo: response.url },
              }),
              // need for img rerendering
              modified_at: new Date().toISOString(),
            });
            setChatInterface({
              ...chatInterface,
              custom_watermark: {
                ...chatInterface?.custom_watermark,
                logo: response.url,
              },
            });
          }
          setLoading(false);
        },
      );
    };

    const deleteWatermarkLogo = () => {
      contentService.deleteChatbotFile(chatbot.uuid, 'chatbot_watermark').then(() => {
        const meta: ChatBotMeta = JSON.parse(chatbot.meta_json);
        updateChatbotByUUID(chatbot.uuid, {
          ...chatbot,
          meta_json: JSON.stringify({ ...meta, custom_watermark: { ...meta?.custom_watermark, logo: '' } }),
        });
        setChatInterface({
          ...chatInterface,
          custom_watermark: {
            ...chatInterface?.custom_watermark,
            logo: '',
          },
        });
      });
    };

    return (
      <>
        <Card id={id} ref={ref} className="relative">
          <CardHeader className="pb-6">
            <CardTitle className="flex items-center">
              <PanelBottom strokeWidth={1.75} className="w-6 h-6 mr-2" />
              Watermark
            </CardTitle>
            <CardDescription>Specify custom watermark to reflect your unique identity.</CardDescription>
          </CardHeader>
          <CardContent className="grid gap-6 max-w-[1000px] overflow-hidden">
            <div className="flex flex-col gap-2">
              <Label
                className="text-md font-medium leading-none tracking-tight flex items-center"
                htmlFor="watermark-logo"
              >
                Logo
              </Label>
              <p className="text-sm text-muted-foreground">
                Upload your watermark logo to brand the chatbot interface, clearly identifying your
                organization as the provider.
              </p>
              <div className="flex items-center gap-2">
                <div
                  id="watermark-logo-container"
                  className="w-[50px] bg-background h-[50px] relative rounded-full border-[1px] flex items-center justify-center"
                >
                  {!chatInterface?.custom_watermark?.logo ? (
                    <IconImage strokeWidth={1.75} className="w-6 h-6" />
                  ) : (
                    <img
                      className="w-[50px] h-[50px] rounded-full"
                      src={`${chatInterface.custom_watermark.logo}?v=${chatbot.modified_at}`}
                      alt="Watermark Logo"
                    />
                  )}
                </div>
                <div>
                  <div className="flex items-center gap-2">
                    <Button
                      disabled={!canUseCustomWatermark || loading}
                      size="sm"
                      variant="outline"
                      className="bg-background"
                      onClick={() => {
                        if (!loading) {
                          watermarkLogoRef?.current?.click();
                        }
                      }}
                    >
                      {loading ? (
                        <Loader2 className="mr-2 w-4 h-4 animate-spin transition-all" />
                      ) : (
                        <UploadIcon strokeWidth={1.75} className="mr-2 h-4 w-4" />
                      )}
                      Upload
                    </Button>
                    <Button
                      disabled={!chatInterface?.custom_watermark?.logo}
                      size="sm"
                      variant="ghost"
                      onClick={deleteWatermarkLogo}
                    >
                      Remove
                    </Button>
                  </div>
                  <p className="text-muted-foreground text-xs mt-1">
                    Supports JPG, PNG, and SVG files up to 1MB
                  </p>
                </div>
              </div>
              <Input
                className="hidden"
                accept="image/png, image/jpg, image/svg+xml"
                ref={watermarkLogoRef}
                id="profile-picture"
                type="file"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    if (file.size <= 1000000) {
                      setImageToUpload(file);
                      e.target.value = '';
                    } else {
                      addAlert({
                        severity: 'error',
                        message: alerts.IMAGE_SIZE_EXCEEDED,
                      });
                    }
                  }
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label
                className="text-md font-medium leading-none tracking-tight flex items-center"
                htmlFor="watermark-label"
              >
                Label
              </Label>
              <p className="text-sm text-muted-foreground">
                Company logo, name, or any other text or symbol you wish to use as a watermark.
              </p>
              <Input
                id="watermark-label"
                type="text"
                disabled={!canUseCustomWatermark}
                value={chatInterface?.custom_watermark?.label}
                onChange={(e) => {
                  setChatInterface({
                    ...chatInterface,
                    custom_watermark: {
                      ...chatInterface?.custom_watermark,
                      label: e.target.value,
                    },
                  });
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label
                className="text-md font-medium leading-none tracking-tight flex items-center"
                htmlFor="watermark-url"
              >
                URL
              </Label>
              <p className="text-sm text-muted-foreground">
                Provide the URL where the watermark will navigate users.
              </p>
              <Input
                id="watermark-url"
                type="text"
                placeholder={mainWebsite}
                disabled={!canUseCustomWatermark}
                value={chatInterface?.custom_watermark?.url}
                onChange={(e) => {
                  setChatInterface({
                    ...chatInterface,
                    custom_watermark: {
                      ...chatInterface?.custom_watermark,
                      url: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </CardContent>
          {!canUseCustomWatermark && (
            <UpgradeRequired className="absolute" heading="Upgrade to Unlock Custom Watermark Feature">
              Please upgrade your plan to access custom watermark. Elevate your experience with personalized
              branding feature.
            </UpgradeRequired>
          )}
        </Card>
        <ImageCropperDialog
          title="Watermark Logo"
          img={imageToUpload}
          cropperCallback={(croppedImage?: File) => {
            if (croppedImage) {
              updateWatermarkLogo(croppedImage);
            }
            setImageToUpload(undefined);
          }}
        />
      </>
    );
  },
);

export default WatermarkAppearance;
