import React from 'react';
import { cn } from 'utils/cn';

const VideoPlayer: React.FC<{ src: string; className?: string }> = ({ src, className }) => {
  const urlPattern =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/;
  const match = src.match(urlPattern);
  const srcId = match ? match[1] : null;

  if (!srcId) return null;

  return (
    <div className={cn('relative w-full pb-[56.25%] h-0 overflow-hidden rounded-lg', className)}>
      <iframe
        title="Chatbot Source"
        src={`https://www.youtube.com/embed/${srcId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="absolute top-0 left-0 w-full h-full rounded-lg"
      />
    </div>
  );
};

export default VideoPlayer;
